import React, { useEffect, useState, useContext } from 'react';
import { resizeNavBar } from './../NavBarJs';
import MyAds from "../../MyAds";

export default function Buy(props) {



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resizeNavBar()
    }, [])



    return (
        <div className="container mt-3 " style={{ paddingTop: "90px" }} onClick={() => resizeNavBar()}>
            <MyAds />
        </div>
    )






}
