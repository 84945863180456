import React, { useEffect, useState, CSSProperties } from 'react';
import { getRandomNumber, generateRandomChats } from '../functions';
import { InfinitySpin, Comment, RotatingLines, MutatingDots } from 'react-loader-spinner'
import Moment from 'react-moment';
import Flip from 'react-reveal/Flip';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';



export default function Facebook(props) {

    const { t } = useTranslation();

    const [firstLoad, setFirstLoad] = useState(false)
    const [isLoadingFb, setIsLoadingFb] = useState(true);
    const { currentNumber, currentChannel, chatsNumbers, unread, profilePic, demoMode } = props

    const [animate, setAnimate] = useState(true)

    const [chats, setChats] = useState([])

    useEffect(() => {


        const getRandomChats = async () => {
            const chatsAux = await generateRandomChats(chatsNumbers, true)
            setChats(chatsAux)
        }
        getRandomChats()



    }, [])


    useEffect(() => {

        if (currentChannel === 'Facebook') {

            if (!firstLoad) {
                setIsLoadingFb(true)
                setTimeout(() => { setIsLoadingFb(false) }, 4000);
                setFirstLoad(true)
            }

            setTimeout(() => { setAnimate(false) }, 20000);//Desp de ese tiempo, no se anima mas. Se carga todo bluroeado
        }

    }, [currentChannel])


    const getCircularReveal = (unread) => {
        if (unread === 0) return null
        return (
            <div className='ml-2 font-weight-bold bg-light ' style={{ marginTop: "3px", color: "#1e7cf2", borderRadius: "50%", width: "15px", height: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "8px" }}>{unread}</span>
            </div>
        )
    }

    if (currentChannel != 'Facebook') return null


    if (isLoadingFb) return (
        <div className="rounded" style={{ backgroundColor: "rgba(30, 124, 242, 1)", height: "558px", width: "278px" }}   >
            <div className='text-center' style={{ padding: "30px" }}>
                <Flip><i className='fa fa-facebook-square fa-brands' style={{ fontSize: "90px", marginTop: "50%" }} /></Flip>
                <Fade><div className='text-center small' style={{ color: "#e5e0e0" }}><i className='fa fa-spinner fa-spin fa-2x mr-1' /> {t('Facebook.cargando')} </div></Fade>
            </div>
        </div>
    );
    return (
        <>
            {/* HEADER**************/}
            <div className='w-100 rounded-top' style={{ backgroundColor: "#0084ff" }}>
                <div className='d-flex justify-content-between pt-2 px-1 py-3'>
                    <span className=' font-weight-bold ml-2' style={{ fontSize: "14px" }}><img src={'./images/fbmsgr.png'} className="" style={{ width: "20px", height: "20px" }} /> Facebook</span>
                    <div className='d-flex justify-content-between '>
                        <i className='fa fa-phone mx-3' />
                        <i className='fa fa-video-camera' />
                        <i className='fa fa-exclamation-circle mx-3' />
                    </div>
                </div>
                <div className='d-flex justify-content-between px-1 small font-weight-bold pb-1'>
                    <div className='d-flex' style={{ borderBottom: "3px solid #fff" }}><i className='fa fa-bars mx-2' style={{ paddingTop: "2px" }} /> Chats  {getCircularReveal(unread)}  </div>
                    <div className='d-flex' style={{ fontSize: "11px" }}>{t('Facebook.historias')} {getCircularReveal(getRandomNumber(0, 0))}</div>
                    <div className='d-flex' style={{ fontSize: "11px" }}>{t('Facebook.llamadas')} {getCircularReveal(getRandomNumber(0, 0))}</div>
                </div>
            </div>
            {/* MENSAJESSSS */}
            <div className="container bg-light text-dark p-0" style={{ height: "479px", overflowY: "auto", borderRadius: "0 0 10px 10px", width: "278px" }}>
                {chats.map((ch, index) => (
                    <FbChatItem key={index} index={index} ch={ch} animate={animate} demoMode={demoMode} />
                ))}
            </div>
        </>
    )
}


function FbChatItem(props) {
    const { ch, index, animate, demoMode } = props

    const { t } = useTranslation();


    const [isLoading, setIsLoading] = useState(false);
    const [cssBlur, setCssBlur] = useState("")

    useEffect(() => {

        let blur = ""
        if (demoMode) blur = "divBlur"

        if (!animate) {
            setCssBlur(blur)
        } else {
            setIsLoading(animate)
            setTimeout(() => {
                setIsLoading(false)

                setCssBlur(true)
                setTimeout(() => { setCssBlur(blur) }, 40);

            }, getRandomNumber(1000, 10000));
        }

    }, [])

    function TypeStyle(props) {
        const { type, isUnread, picture } = props
        if (isUnread) return null

        //Entregado
        if (type === 1) return <span className='text-secondary' style={{ fontSize: "13px" }}><i className='fa fa-check' /></span>
        //Entregado y LEIDO
        if (type === 2 || type === 3) return <img src={ch.picture} className="rounded-circle" style={{ width: "16px", height: "16px" }} />

        return null
    }



    if (isLoading) return (
        <div className='mt-2 text-center d-flex px-2'>
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
            />
            {/* <div className='text-center small text-dark ml-1' ><span className='divBlur'>{ch.conntactName}</span></div> */}
            <div className='text-center small text-dark ml-1 ' >{t('Facebook.cargandoChat')}</div>
        </div>
    )

    const getUnreadColor = (isUnread) => {
        if (isUnread) return "#000"
        return "#212528"
    }
    const isValidDate = (fechaString) => {
        const fecha = new Date(fechaString);
        return !isNaN(fecha) && fecha.toString() !== 'Invalid Date';
    }

    const getTextStyle = (isUnread) => {
        if (isUnread) return "font-weight-bold"
        return ""
    }

    const getBorderStyle = () => {
        let haveStatusProbability = getRandomNumber(1, 3)
        if (haveStatusProbability === 3) return "3px solid #1e7cf2"
        return "2px solid #fff"
    }


    return (
        <div key={index} className={`d-flex justify-content-between my-2 ${getTextStyle(ch.unread)} ${cssBlur} px-2`}>
            <div className='d-flex ' style={{ fontSize: "0.8em" }}>


                <img src={ch.picture} className="rounded-circle" style={{ width: "40px", height: "40px", border: getBorderStyle() }} />

                <div className='mx-1 text-left'>
                    <div >{ch.conntactName} </div>
                    <div style={{ color: "#000000" }}>{ch.snippet.substring(0, 25) + ""}</div>
                </div>
            </div>
            <div className='small' >

                <div className={`${getTextStyle(ch.unread)} d-flex justify-content-center`} style={{ color: getUnreadColor(ch.unread) }}>
                    <div className=''>
                        {isValidDate(ch.lastMessage.toString()) ?
                            <Moment date={ch.lastMessage} format="DD MMM" />
                            :
                            <div>{ch.lastMessage.toString()}</div>
                        }
                        <div className='text-center' style={{ color: "#4c4f50" }}><TypeStyle type={ch.type} isUnread={ch.unread} picture={ch.picture} /></div>
                    </div>
                </div>

                {ch.unread &&
                    <div className='d-flex justify-content-center mt-1'>
                        <div className=' ml-2 font-weight-bold bg-primary' style={{ color: "#fff", borderRadius: "50%", width: "16px", height: "16px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <span style={{ fontSize: "10px" }}>{getRandomNumber(1, 6)}</span>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}