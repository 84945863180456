import React, { useEffect, useState, useRef, useContext } from "react";
import { resizeNavBar } from './NavBarJs';

import MiContexto from '../MiContexto'
import { MagnifyingGlass, Radio, MutatingDots } from 'react-loader-spinner'

import DeviceInfo from './Features/DeviceInfo';
import { set } from "date-fns";
import { getRandomNumber } from "./Features/functions";
import Globe from 'react-globe.gl'

import MyAds from "../MyAds";


export default function MobileWithPayment(props) {

    const currentMobile = useContext(MiContexto).currentMobile
    const [isLoading, setIsLoading] = useState(false);
    const [showData, setShowData] = useState(false);

    const [aLocation, setLocation] = useState({ countryName: "", countryFlag: "", regionName: "", regionCode: "", postal: "", latitude: "", longitude: "" })

    const globeEl = useRef();
    const divDynamic = document.createElement('div');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resizeNavBar()


        let loc = currentMobile?.location

        if (!loc) return null
        let countryName = loc.split("|")[0]
        let countryFlag = loc.split("|")[1]
        let regionName = loc.split("|")[2]
        let regionCode = loc.split("|")[3]
        let postal = loc.split("|")[4]
        let latitude = loc.split("|")[5]
        let longitude = loc.split("|")[6]

        setLocation({ countryName: countryName, countryFlag: countryFlag, regionName: regionName, regionCode: regionCode, postal: postal, latitude: latitude, longitude: longitude })

        // setIsLoading(true)
        // setTimeout(() => { setIsLoading(false) }, getRandomNumber(5000, 7000));

        if (globeEl.current) {
            globeEl.current.pointOfView({ lat: latitude, lng: longitude, altitude: 1.5 }, 2000);

            divDynamic.innerHTML =
                `<div class="small" style='padding-top:150px'>
                <button class='btn btn-danger btn-sm font' >Ver Ubicación Exacta <i class="fa fa-street-view" aria-hidden="true"></i></button>
                <div class=' small' style='text-transform: uppercase'> ${regionCode}</div>
                <div class=' small'> ${postal}</div>
                <h1>CONECTANDO</h1>
                <img src=${countryFlag}  class='img-fluid' style='height:20px'  /> <span class='small' style=''>${countryName}</span>
                </div>`;

            }

    }, [])



    if (!currentMobile) return null
    if (!currentMobile.payment) return null
    if (!currentMobile.location) return null


    const handleShowDeviceFounded = () => {
        console.log("hola")
        setShowData(true)
    }


    if (isLoading) return (
        <div className='container mt-2 ' style={{ paddingBottom: "50px", paddingTop: "150px", height: "100vh" }}>


            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#dc3545"
                secondaryColor="#dc3545"
                radius="15.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                wrapperClass=""
            />
            <div className='text-center small' style={{ color: "#dc3545" }}>Cargando información del dispositivo {currentMobile.number}</div>

        </div>
    );


    return (
        <div className="container mt-2 " style={{ paddingBottom: "50px", height: "100vh" }} onClick={() => resizeNavBar()}>


            <h1>{currentMobile?.number}</h1>


            {currentMobile && <DeviceInfo currentNumber={currentMobile.number} network={currentMobile.network} hardware={currentMobile.hardware} />}

            <MyAds />

            {!setShowData ?
                <div className='d-flex justify-content-center'></div>
                :
                <div className="p-0 mt-1 d-flex justify-content-center">
                    {/* <Map lat={geoLocation.location.latitude} lng={geoLocation.location.longitude} oneUpdate={true} /> */}
                    <Globe
                        ref={globeEl}
                        globeImageUrl="//unpkg.com/three-globe/example/img/earth-day.jpg"
                        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                        width="300px"
                        height="300px"
                        showGraticules={true}
                        //Puntos
                        pointsData={[{
                            lat: aLocation.latitude,
                            lng: aLocation.longitude,
                            size: 0.5,
                            color: 'red',
                            label: 'MÓVIL ENCONTRADO!',
                        }]}
                        pointAltitude="size"
                        pointColor="color"
                        pointLabel="label"
                        pointRadius={0.5}
                        pointsTransitionDuration={3000}
                        //Arcos
                        //arcsData={[{ startLat: 37.7749, startLng: -122.4194, endLat: 34.0522, endLng: -118.2437 }, { startLat: 40.7128, startLng: -74.0060, endLat: 51.5074, endLng: -0.1278 }, { startLat: 51.5074, startLng: -0.1278, endLat: 55.7558, endLng: 37.6176 }]}
                        Anillos
                        ringsData={[{
                            lat: aLocation.latitude,
                            lng: aLocation.longitude,
                            size: 0.8,
                            color: 'red',
                        }]}
                        ringColor="color"
                        //DIV PERSONALIZADO
                        htmlElementsData={[{ lat: aLocation.latitude, lng: aLocation.longitude }]}
                        htmlElement={divDynamic}
                        animateIn={true}
                        toRotate={true}
                        autoRotate={true}
                    />

                </div>
            }



        </div>
    )


}