import { set } from 'date-fns';
import { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { URL_BACKEND } from "./config";
import { ca } from 'date-fns/locale';

export default async function WppApi2(aNumber) {



    const wppApi = {
        profilePic: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
        about: '',
        warning: ''
    }

    // const wppApi = {
    //     profilePic: 'https://catwatchful.com/avatar-br.jpg',
    //     about: 'I need a vacation🥰❤️',
    //     warning: ''
    // }

    if (!aNumber) return null

    try {

        const response = await axios.get(`${URL_BACKEND}/wppInfo/?number=${aNumber}`)
        if (response) {
            if (response.status == 200 && response.data) {
                console.log("response", response.data)

                const pic = response.data.profilePicUrl
                const about = response.data.about

                if (pic && pic != "undefined")
                    wppApi.profilePic = response.data.profilePicUrl

                if (about && about != "undefined")
                    wppApi.about = response.data.about

                // HUBO ERROR INTERNO pero sigo funcionando <==> El error no fue que el numero no fue encontrado
                if (response.data.errCode) {
                    console.log("ERROR CON wppApi", response.data)
                    if (response.data.errCode == "403") wppApi.warning = "403"//NO SE ENCONTRÓ EL NÚMERO"
                }

            } else {
                console.log("error", response.data)
                wppApi.warning = response.data
            }
        } else {
            wppApi.warning = "No response"
        }

    } catch (e) {
        console.log("error", e)
        wppApi.warning = e.response?.data
    }

    return wppApi



}