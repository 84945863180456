import { randomizeArray } from './functions'

export const names = [
  'José', 'María', 'Juan', 'Ana', 'Luis', 'Laura', 'Francisco', 'Guadalupe',
  'Miguel', 'Carmen', 'Antonio', 'Rosa', 'Carlos', 'Patricia', 'Alejandro',
  'Verónica', 'Javier', 'Elizabeth', 'Daniel', 'Gabriela', 'Manuel', 'Adriana',
  'Raúl', 'Brenda', 'Ricardo', 'Diana', 'Jorge', 'Alma', 'Fernando', 'Vanessa',
  'Roberto', 'Ana Karen', 'Pedro', 'Isabel', 'Gerardo', 'Claudia', 'Ernesto',
  'Paola', 'Oscar', 'Silvia', 'Ramón', 'Lorena', 'Arturo', 'Monica', 'Enrique',
  'Beatriz', 'Raquel', 'Héctor', 'Lorena', 'Isaac'
];

// export const names = [
//   'James', 'John', 'Robert', 'Michael', 'William', 'David', 'Richard', 'Joseph', 'Charles', 'Thomas',
//   'Daniel', 'Matthew', 'Christopher', 'Andrew', 'Anthony', 'Elizabeth', 'Jennifer', 'Patricia', 'Linda', 'Barbara',
//   'Mary', 'Jessica', 'Sarah', 'Karen', 'Nancy', 'Lisa', 'Betty', 'Margaret', 'Susan', 'Dorothy',
//   'Jessica', 'Sarah', 'Karen', 'Michelle', 'Emily', 'Ashley', 'Samantha', 'Amanda', 'Melissa', 'Stephanie',
//   'Nicole', 'Christine', 'Rebecca', 'Rachel', 'Lauren', 'Anna', 'Kimberly', 'Angela', 'Cynthia', 'Laura'
// ];



export const surnames = [
  'González', 'Rodríguez', 'Gómez', 'Fernández', 'López', 'Díaz', 'Martínez',
  'Pérez', 'Gutiérrez', 'Sánchez', 'Romero', 'Suárez', 'Torres', 'Vargas',
  'Ramírez', 'Flores', 'Castillo', 'Jiménez', 'Hernández', 'Ortiz', 'Moreno',
  'Álvarez', 'Ruiz', 'Cruz', 'Reyes', 'Ramos', 'Mendoza', 'Chávez', 'Méndez',
  'Cabrera', 'Acosta', 'Molina', 'Aguilar', 'García', 'Herrera', 'Vázquez',
  'Núñez', 'Rojas', 'Guerrero', 'Medina', 'Vega', 'Ponce', 'Ibarra', 'Soto',
  'León', 'Arias', 'Contreras'
];

// export const surnames = [
//   'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez',
//   'Hernandez', 'Lopez', 'Gonzalez', 'Wilson', 'Anderson', 'Thomas', 'Taylor', 'Moore', 'Jackson', 'Martin',
//   'Lee', 'Perez', 'Thompson', 'White', 'Harris', 'Sanchez', 'Clark', 'Ramirez', 'Lewis', 'Robinson',
//   'Walker', 'Young', 'Allen', 'King', 'Wright', 'Scott', 'Torres', 'Nguyen', 'Hill', 'Flores', 'Green',
//   'Adams', 'Nelson', 'Baker', 'Hall', 'Rivera', 'Campbell', 'Mitchell', 'Carter', 'Roberts', 'Gomez'
// ];

export const nicks = [
  'Chato', 'Chiquis', 'Güero', 'Negro', 'Flaca', 'Pelón', 'Chispa', 'Gordo',
  'Cuco', 'Chiquito', 'Lalo', 'Licha', 'Toto', 'Nena', 'Chente', 'Chela',
  'Pato', 'Pancha', 'Memo', 'Rosa', 'Tito', 'Coco', 'Chispi', 'Rafa', 'Fifi',
  'Lupita', 'Chaparro', 'Güera', 'Chivo', 'Chuy', 'Pipi', 'Chofi', 'Mono',
  'Checo', 'Chavela', 'Chuyito', 'Chica', 'Chino', 'Yayo', 'Chiquilín', 'Rulo',
  'Chaparrito', 'Chelito', 'Cachetes', 'Chapis', 'Chule', 'Chuchis', 'Chamuco',
  'Pelusa', 'Chisguete', 'Chispeante', 'Cachorro', 'Chivito', 'Chupete', 'Chispy',
  'Güerito', 'Moreno', 'Rubio', 'Chispeado', 'Peloncito', 'Chamaco', 'Princesa',
  'Vaquero', 'Chingón', 'Reina', 'Rey', 'Chiquis', 'Chaparrita', 'Güerita',
  'Gordita', 'Flaquito', 'Chino', 'Chilango', 'Charro', 'Vaquera', 'Chulita',
  'Chiquitín', 'Pequeño', 'Príncipe', 'Chula', 'Amigo', 'Güero', 'Güerita',
  'Niño', 'Niña', 'Loco', 'Loca', 'Bebé', 'Cielo', 'Estrella', 'Corazón',
  'Mariposa', 'Sol', 'Luna', 'Dulce', 'Caramelo', 'Bombón', 'Amor', 'Gorda', 'Gordis', 'Mecanico'
];

// export const nicks = [
//   'Ace', 'Angel', 'Bear', 'Blaze', 'Breezy', 'Buddy', 'Coco', 'Cookie', 'Dash', 'Duke',
//   'Foxy', 'Giggles', 'Guru', 'Hawk', 'Indy', 'Jazzy', 'Joker', 'Kitty', 'Lucky', 'Maverick',
//   'Misty', 'Ninja', 'Panda', 'Peaches', 'Pebbles', 'Pirate', 'Princess', 'Ranger', 'Raven', 'Rebel',
//   'Rogue', 'Scooter', 'Shadow', 'Sunny', 'Tiger', 'Viper', 'Whisper', 'Xena', 'Ziggy', 'Angel',
//   'Blaze', 'Coco', 'Duke', 'Foxy', 'Guru', 'Hawk', 'Indy', 'Jazzy', 'Joker', 'Kitty',
//   'Lucky', 'Maverick', 'Misty', 'Ninja', 'Panda', 'Peaches', 'Pebbles', 'Pirate', 'Princess', 'Ranger',
//   'Raven', 'Rebel', 'Rogue', 'Scooter', 'Shadow', 'Sunny', 'Tiger', 'Viper', 'Whisper', 'Xena', 
//   'Ziggy'
// ];


export const snippets = ["Jocelyn te envió un mensaje.", "Itzel le va marcar a Juan ahorita me regresa la llamada", "Que paso siempre con su camión",
  "Donde esta usted ?", "Me hubieras marcado para regresarme para que no estuvieras sola", "🎥 Video (0:08)", "Es un chocolate mi ex nv jaja 😂😂",
  "Holiii 🤙🏻", "💟 Sticker", "Oye😟", "🎥 Video (0:56)", "🎥 Video (0:18)", "🎵 Mensaje de voz (0:03)", "No ma así tienes👀", "Hola, como estas?",
  "🎥 Video (0:05)", "Esta bien espero que te diviertas😁", "📷 Foto", "📷 miraa!!!", "Mira😣😭", "Solamente me esta ignorando eso creo no se que opinas😟😣😭",
  "Que bueno como te fue?", "Que tienen👀", "Deja la agarro con los msj que deja les digoo", "No me carga el video", "pero no es asi", "solo que no me gusta que me deje en visto",
  "y no me conteste", "pero bueno", "que bueno que te fue bien", "me alegro mucho", "y que bueno que te gusto la comida", "Tqm🥹❤",
  "siempre es bueno probar cosas nuevas", "Su estado de pam😂😮"]

// export const snippets = [
//   "Hey! How's it going?",
//   "What's up?",
//   "I'm so tired 😴",
//   "Did you hear about the new restaurant downtown? 🍽️",
//   "Can't wait for the weekend!",
//   "Are you coming to the party tonight?",
//   "Just got back from the gym 💪",
//   "I love this weather! ☀️",
//   "Let's grab coffee sometime!",
//   "What movie should we watch tonight?",
//   "I'm stuck in traffic 🚗",
//   "Wanna grab lunch later?",
//   "I miss you! ❤️",
//   "I'm feeling stressed out lately 😓",
//   "Have you tried that new pizza place?",
//   "Heading to the beach this weekend 🏖️",
//   "Thinking of starting a new hobby, any suggestions?",
//   "How was your day?",
//   "I'm binge-watching a new series 📺",
//   "Can't believe it's already March!",
//   "Do you have any plans for the holidays?",
//   "I need a vacation!",
//   "Let's plan a road trip 🚗💨",
//   "Coffee is my fuel ☕",
//   "I'm craving sushi 🍣",
//   "Have you read any good books lately? 📚",
//   "Going for a hike this weekend 🌲",
//   "Got any Netflix recommendations?",
//   "Work is so hectic right now!",
//   "I'm loving this new song 🎶",
//   "Let's do brunch on Sunday!",
//   "Need coffee ASAP!",
//   "Can't decide what to wear tonight 🤔",
//   "Thinking of redecorating my room 🛋️",
//   "I'm finally done with exams!",
//   "Happy Friday! 🎉",
//   "What's your favorite workout routine?",
//   "I'm in the mood for ice cream 🍦",
//   "Have you seen the latest Marvel movie?",
//   "Just finished cooking dinner 🍲",
//   "I need a new phone!",
//   "Let's go for a bike ride 🚲",
//   "Game night this Friday, you in?",
//   "I'm addicted to TikTok 🕺",
//   "Need some new podcasts to listen to 🎧",
//   "Trying to eat healthier this week 🥗",
//   "Can you believe what happened on the news?",
//   "I'm learning how to play the guitar 🎸",
//   "Road trip playlist suggestions, anyone?",
//   "Thinking of adopting a pet 🐶",
//   "Let's meet up for drinks 🍸",
// ];

export function getProfilePictures() {
  function importAll(r) { return r.keys().map(r); }
  let imagesAux = importAll(require.context('../../../public/images/profilePictures', false, /\.(png|jpe?g|svg)$/));
  randomizeArray(imagesAux)
  return imagesAux
}


export function getDevicePictures() {
  function importAll(r) { return r.keys().map(r); }
  let imagesAux = importAll(require.context('../../../public/images/devicePictures', false, /\.(png|jpe?g|svg)$/));
  randomizeArray(imagesAux)
  return imagesAux
}

export const possibleModels=['SM-G531H','SM-G532M','SM-G532G','OPO A37f','SM-G610M',
'SM-G570M','SM-G532M','SM-G532G','SM-G532F','V2310',
'SM-A307G','SM-A105M','M2006C3LG','SM-S911B','SM-A217',
'21121119SG','CPH1723',' M2006C3LG','SM-A135M','OPO-325']

export const wifiNames = ["Casa 2.4G", "CasaNet24", "Rápido2.4", "WaveG2.4", "GigaHome", "VelozC24", "2p4GFun", "CasaRáp2", "Nube2.4","24GBliss", "F2p4Fam", "CasaG2.4", "Red2.4GH", "Hola2p4", "ByteC2.4", "Con2p4!", "Fast24GH", "F2p4Zone", "24GZoom", "GoCasa24",
"Súper24G", "G2p4Life", "24GFizz", "2p4GMax", "Quick24G", "G2p4Casa", "24G1der", "H2p4Cool", "Casa2Go", "24GByte", "Blink24G", "Joya2p4", "JetC2p4", "CasaX24","24GFlow", "2p4GEMS", "G2p4Joy", "CasaFun!", "M24GFun", "24GSwift", "Wiz2p4", "Wow2p4G", "Zippy24G", "G2p4Zoo", "CasaUp!", "Q24GJoy", "24GPop!", "CasaWow", "Snap24G","2p4GJaz", "V2p4Now"]



export const possibleOs=['Android 10','Android 11','Android 12','Android 13']