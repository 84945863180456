import { gl, is } from 'date-fns/locale';
import { useEffect, useState, useRef, useContext } from 'react';


import { getRandomNumber, getRandomString, playAudio } from './Features/functions';
import { resizeNavBar } from './NavBarJs';
import React from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import { set } from 'date-fns';
import Moment from 'react-moment';
import { sleepTime } from './Features/functions';
import MyAds from "../MyAds";
import { useTranslation } from 'react-i18next';
import ReactTypingEffect from 'react-typing-effect';

import MiContexto from '../MiContexto'


export default function ServiceStatus(props) {

    const { t } = useTranslation();
    const {adsMode } = useContext(MiContexto)

    const [terminalLineData, setTerminalLineData] = useState([
        <TerminalOutput> <div className='small text-warning'><Moment date={Date.now()} format="LLL" /></div></TerminalOutput>,
        <TerminalOutput><div className='small text-danger mb-2'>{t("ServiceStatus.title")}  </div></TerminalOutput>
    ]);

    const [terminalInputs, setTerminalInputs] =
        useState([
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text1")} </span><i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text2")} </span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text3")}: <Moment date={Date.now()} format="LLL" /> </span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text4")} </span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text5")}</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text6")}: {getRandomNumber(5, 15)}</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text7")}: {getRandomNumber(10, 50)}</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text8")}</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text9")}: {getRandomNumber(100, 500)}ms</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text10")}</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text11")} {getRandomNumber(3, 10)}/10</span> <i className='fa fa-check text-success' /> </div>,
            <div className=''>Server: <span className='text-warning'>{t("ServiceStatus.text12")} {getRandomNumber(80, 100)}/100%</span> <i className='fa fa-warning text-warning' /> </div>,
            <div className=''>Server: <span className='text-success'>{t("ServiceStatus.text13")} {getRandomString(20)}</span> <i className='fa fa-check text-success' /> </div>,
        ])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resizeNavBar()

        terminalInputs.forEach(async (input) => {
            await sleepTime(getRandomNumber(3000, 8000))
            setTerminalLineData((prev) => {
                return [...prev, <TerminalOutput><div className='small text-left'>{input}</div></TerminalOutput>]
            })

        })




    }, [])

    const handleOnInput = (input) => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        // setTerminalLineData((prev) => {
        //     return [...prev, <TerminalOutput>{input}</TerminalOutput>]
        // })
        return null
    }

    return (
        <div className='container small' style={{ height: "100vh" }} onClick={() => resizeNavBar()}>
            <div className='h4 text-uppercase' style={{ paddingTop: "25px" }}>{t("ServiceStatus.firstTitle")}</div>




            <div className='' style={{ tabIndex: "-1", marginTop:"30px" }}>
                <Terminal name=''
                    height='550px'
                    prompt='>'
                    colorMode={ColorMode.Dark}
                // onInput={handleOnInput}
                >
                    <div className='small text-left' style={{ lineHeight: "11px" }}>{terminalLineData}</div>

                    <div className='mt-2 text-left small font-weight-bold'>
                         <ReactTypingEffect cursor={"█"} speed={20} eraseDelay={99999} text="CWF Network is ready to Use "  />
                    </div>
                    
                </Terminal>
            </div>

        </div>
    )

}