import { gl, is } from 'date-fns/locale';
import { useEffect, useState, useRef, useContext } from 'react';

import Globe from 'react-globe.gl'
import { CSS2DRenderer } from 'three/addons/renderers/CSS2DRenderer.js';
import { getRandomNumber,playAudio } from './Features/functions';
import { useTranslation } from 'react-i18next';

export default function Planet(props) {

    const { t } = useTranslation();

    const globeEl = useRef();
    const mobile = props.mobile

    const [isSearching, setIsSearching] = useState(true)
    const [isConnecting, setIsConnecting] = useState(false)

    const divDynamic = document.createElement('div');




    useEffect(() => {
        if (!mobile) return null


        if (globeEl.current) {

            if (isSearching) {
                playAudio('beep')
                globeEl.current.controls().autoRotate = true;
                globeEl.current.controls().autoRotateSpeed = 1;

                divDynamic.innerHTML =
                    `<div style='padding-top:0px'>
                <h3><div class='small' style='padding-top:20px; color:green' >  <i class=" fa fa-spinner fa-spin fa-2x"></i> ${t('Planet.conectando')}</div> ${t('Planet.buscando')} </h3>
                <div>+${mobile.code} ${mobile.number}</div>
                </div>`
            }

            if (isConnecting) {
                playAudio('beep2')
                globeEl.current.pointOfView({ lat: mobile.geoLocation.location.latitude, lng: mobile.geoLocation.location.longitude, altitude: 2 }, 2000);
                globeEl.current.controls().autoRotate = false;

                divDynamic.innerHTML =
                    `<div style='padding-top:230px'>
                <img src=${mobile.wppPicture}  class="rounded-circle blob red" />
                <div style='color:red;font-weight: bold' >${t('Planet.deviceFound')}</div>
                <div class=' small' style='text-transform: uppercase'><i class="fa fa-podcast"></i> ${mobile.geoLocation.connection?.domain?.split('.')[0]}</div>
                <div class=' small'> ${mobile.geoLocation.connection.organization}</div>
                <img src=${mobile.geoLocation.location.country.flag.wikimedia}  class='img-fluid' style='height:20px'  /> <span class='small' style=''>${mobile.geoLocation.location.country.name}</span>
                <div class='small' style='padding-top:20px; color:green; font-weight: bold' >  <i class=" fa fa-spinner fa-spin fa-2x"></i> ${t('Planet.conectando2')}</div>
                </div>`;


                setTimeout(() => {
                    globeEl.current.pointOfView({ lat: mobile.geoLocation.location.latitude, lng: mobile.geoLocation.location.longitude, altitude: 0.4 }, 2000);
                    divDynamic.innerHTML =
                        `<div style='padding-top:0px '>
                        <img src=${mobile.wppPicture}  class="rounded-circle blob green" />
                        <h4>
                        <div class='small' style='padding-top:5px; color:green' >  <i class=" fa fa-spinner fa-spin fa-2x"></i> ${t('Planet.conectando3')}</div> 
                        ${t('Planet.accediendoRed')} <span style='text-transform: uppercase'> ${mobile.geoLocation.connection?.domain?.split('.')[0]}</span>
                        </h4>
                        <div>+${mobile.code} ${mobile.number}</div>
                    </div>`
                }, 8000);
                setTimeout(() => {
                    divDynamic.innerHTML =
                        `<div style='padding-top:0px'>
                        <img src=${mobile.wppPicture}  class="rounded-circle blob green" />
                        <h4><div class='small' style='padding-top:5px; color:green' >  <i class=" fa fa-spinner fa-spin fa-2x"></i> ${t('Planet.conectando4')}</div> ${t('Planet.recuDatos')}</h4>
                        <div>+${mobile.code} ${mobile.number}</div>
                    </div>`
                }, 10000);
                setTimeout(() => {
                    divDynamic.innerHTML =
                        `<div style='padding-top:0px'>
                        <img src=${mobile.wppPicture}  class="rounded-circle blob green" />
                        <h4><div class='small' style='padding-top:5px; color:green' >  <i class=" fa fa-spinner fa-spin fa-2x"></i> ${t('Planet.conectando5')}</div> ${t('Planet.clonando')} </h4>
                        <div>+${mobile.code} ${mobile.number}</div>
                    </div>`

                }, 12000);

                //FINNN
                setTimeout(() => {
                    props.handleShowDeviceFounded()
                }, 15000);
            }
        }
    }, [mobile, isConnecting])


    useEffect(() => {

        if (isSearching) {
            setTimeout(() => {
                setIsSearching(false)
                setIsConnecting(true)
            }, getRandomNumber(10000, 15000))
        }
    }, [])





    if (isSearching) {
        return (
            <div className='overlay fade-in-div' style={{  position: 'absolute' }} >
                {isSearching &&
                    <Globe
                        ref={globeEl}
                        globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
                        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                        width="100vh"
                        height="100vh"
                        showGraticules={true}
                        //DIV PERSONALIZADO
                        htmlElementsData={[{ lat: 0, lng: -35 }]}
                        htmlElement={divDynamic}
                    />}
            </div>
        )
    }

    if (isConnecting) {
        return (
            <div className='overlay' style={{ position: 'absolute' }} >
                <Globe
                    ref={globeEl}
                    globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
                    backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                    width="100vh"
                    height="100vh"
                    showGraticules={true}
                    //Puntos
                    pointsData={[{
                        lat: mobile.geoLocation.location.latitude,
                        lng: mobile.geoLocation.location.longitude,
                        size: 0.5,
                        color: 'red',
                        label: t('Planet.movilEncontrado'),
                    }]}
                    pointAltitude="size"
                    pointColor="color"
                    pointLabel="label"
                    pointRadius={0.5}
                    pointsTransitionDuration={3000}
                    //Arcos
                    //arcsData={[{ startLat: 37.7749, startLng: -122.4194, endLat: 34.0522, endLng: -118.2437 }, { startLat: 40.7128, startLng: -74.0060, endLat: 51.5074, endLng: -0.1278 }, { startLat: 51.5074, startLng: -0.1278, endLat: 55.7558, endLng: 37.6176 }]}
                    //Anillos
                    // ringsData={[{
                    //     lat: geoLocation.location.latitude,
                    //     lng: geoLocation.location.longitude,
                    //     size: 0.7,
                    //     color: 'red',
                    // }]}
                    // ringColor="color"
                    //DIV PERSONALIZADO
                    htmlElementsData={[{ lat: mobile.geoLocation.location.latitude, lng: mobile.geoLocation.location.longitude }]}
                    htmlElement={divDynamic}
                    animateIn={true}
                    toRotate={true}
                    autoRotate={true}
                />


            </div>
        )

    }

    return null



}