import { useEffect, useState } from 'react';
import { Grid} from 'react-loader-spinner'
import { getDevicePictures } from './data'
import Bounce from 'react-reveal/Bounce';
import { getRandomNumber, sleepTime, randomizeArray,playAudio } from './functions';
import { FeatureTitle } from './CommonVisuals'
import { ImagePixelated } from "react-pixelate"
import { useTranslation } from 'react-i18next';



export default function DevicePictures(props) {

    const { t } = useTranslation();

    const {currentChannel,demoMode}=props

    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImg, setCurrentImg] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [percentLoading, setPercentLoading] = useState(0);

    const [refreshCount, setRefreshCount] = useState(0)


    useEffect(() => {

        const startLoading = async () => {

            getImages()

            // setIsLoading(true)
            // for (let i = 1; i < 100; i++) {
            //     await sleepTime(250)
            //     setPercentLoading(i)
            // }
            // setIsLoading(false)

        }
        startLoading();

    }, [])


    useEffect(() => { }, [selectedImage])

    function importAll(r) {
        return r.keys().map(r);
    }

    const getImages = async () => {
        let imagesAux = getDevicePictures()
        let firstImages = imagesAux.slice(0, getRandomNumber(40, 70))

        if (refreshCount == 0 || refreshCount == 3 || refreshCount == 6 || refreshCount == 10)
            firstImages.unshift(props.profilePic)
        setRefreshCount(refreshCount + 1)


        setImages(firstImages)
    }


    useEffect(() => {
        // console.log(selectedImage)
    }, [selectedImage])

    if (currentChannel != 'Files') return null

    if (isLoading) return (
        <div className='text-center'>
            <FeatureTitle ftName={t('DevicePictures.archivos')} icon='fa-image' />

            <div className='badge px-2 fondoTransRojo rounded small font-weight-bold text-danger'>{images.length} {t('DevicePictures.encontradas')}</div>
            <div className='text-center small mt-2 text-danger' style={{ color: "#00C3F7" }}>{t('DevicePictures.cargando')}</div>
            <div className="progress mt-0 mx-4" style={{ height: "29px" }}>
                <div className="progress-bar progress-bar-striped bg-danger font-weight-bold" role="progressbar" style={{ width: percentLoading + "%", fontSize: "13px", color: "#fff" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> {percentLoading}%</div>
            </div>

        </div>
    );

    
    return (

        <div className='rounded ' style={{height:"559px",width:"278px", backgroundColor:"#000"}}>
            <FeatureTitle ftName={t('DevicePictures.archivos')}  icon='fa-image' />
            <ImageGallery gallery={images} handleBuy={props.handleBuy} demoMode={demoMode} />
        </div>

    )



}

function ImageGallery(props) {
    const { t } = useTranslation();

    const  {gallery,demoMode} = props
    const [selectedImage, setSelectedImage] = useState(null);

    const [pagination, setPagination] = useState(0)

    const ITEMS = 18


    const handleImageSelect = async (aImage) => {
        setSelectedImage(aImage)
        await sleepTime(500)
        window.$('#ShowImageModal').modal();
    }

    const paginate = (increase) => {

        if (increase) {
            if (pagination + 1 < gallery.length / ITEMS)
                setPagination(pagination + 1)
        } else {
            if (pagination - 1 >= 0)
                setPagination(pagination - 1)
        }
    }


    useEffect(() => {
        if (!gallery) return null

        console.log(gallery)
    }, [gallery])


   

    return (
        <div style={{marginTop:"50px"}}>
              
           <ImageDialog image={selectedImage} handleBuy={props.handleBuy} demoMode={demoMode}/>
           
            <div className='d-flex justify-content-between'>

                <Bounce><div className=' ' onClick={() => paginate(false)} > <i className='fa fa-arrow-circle-left text-light' /></div></Bounce>

                <div className='d-flex flex-wrap justify-content-center'>
                    {gallery.slice(pagination * ITEMS, pagination * ITEMS + ITEMS).map((image, index) => {
                        return <span onClick={()=>handleImageSelect(image)}><Image key={index} image={image} demoMode={demoMode} /></span>
                    })}
                </div>

                <Bounce><div onClick={() => paginate(true)}> <i className=' fa fa-arrow-circle-right text-light' /></div></Bounce>

            </div>

            <div className='badge px-2 fondoTransRojo rounded small font-weight-bold text-danger mt-4'>{gallery.length} {t('DevicePictures.encontradas')}</div>
        </div>
    )
}

function Image(props) {

    const { image,demoMode } = props
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => { setIsLoading(false) }, getRandomNumber(100, 13000));
    }, [props.image])


    if (isLoading) return (
        <span className='' style={{ width: "60px", height: "60px" }}>
            <Grid color="#dc3545"   wrapperClass="grid-wrapper" animationDuration="1" width="30" visible={true}   radius="12.5" />
        </span>
    );

    let blur=""
    if(demoMode)blur="divBlur"


    return (
        <Bounce>
            <span className={`mx-1 border border-secondary rounded ${blur}`} >
                <img src={image} alt="" width={60} height={60} />
            </span>
        </Bounce>
    )
}


function ImageDialog(props) {

    const { t } = useTranslation();

    const { image ,demoMode} = props

    const [isLoading, setIsLoading] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [imageName, setImageName] = useState(null)

    const [isProfilePic, setIsProfilePic] = useState(false)


    useEffect(() => {

        const loadImage = async () => {
            if (image) {
                let aImageName = image.split('/')[3]
                if (aImageName.length >= 40) aImageName = aImageName.substring(0, 15) + '.jpg'

                if (aImageName == 'v') {
                    setIsProfilePic(true)
                    aImageName = `CAM_PICTURE_${new Date().getHours()}.jpg`
                } else {
                    setIsProfilePic(false)
                }

                setImageName(aImageName)

                setIsLoading(true)
                await sleepTime(getRandomNumber(1000, 10000))
                playAudio('image')
                setIsLoading(false)
                setCurrentImage(image)
            }
        }

        loadImage()
    }, [image])


    let blur=""
    if(demoMode)blur="blur(4px)"
   

    return (

        <div className="modal fade" id="ShowImageModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{position:"absolute"}}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title text-dark px-2" id="exampleModalLongTitle" style={{ wordWrap: 'break-word' ,fontSize:"0.8em"}}>{imageName}</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='text-center'>
                            {isLoading || !currentImage ?
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <Grid
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#6c757d"
                                            ariaLabel="grid-loading"
                                            radius="12.5"
                                            wrapperClass="grid-wrapper"
                                        />
                                    </div>
                                    <div className='text-center small text-dark mt-2'>{t('DevicePictures.cargandoImg')}</div>
                                </div>
                                :
                                <div>
                                    {isProfilePic ?
                                        // <ImagePixelated src={currentImage} pixelSize={4} width={220} height={350} fillTransparencyColor={"grey"} />
                                        <img src={currentImage} alt="" className='img-fluid' style={{filter:blur}}/>
                                        :
                                        // <ImagePixelated src={currentImage} pixelSize={15} width={220} height={350} fillTransparencyColor={"grey"} />
                                        <img src={currentImage} alt="" className='img-fluid' style={{filter:blur}}/>
                                    }
                                    <button type="button" className="btn btn-danger btn-block" data-dismiss="modal" onClick={()=>props.handleBuy()}>{t('DevicePictures.desbloquear')}</button>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('DevicePictures.cerrar')}</button>

                    </div>
                </div>
            </div>
        </div>
    )

}
