import React, { useEffect, useState, CSSProperties } from 'react';
import { getRandomNumber, generateRandomChats } from '../functions';
import { InfinitySpin, Comment, RotatingLines, MutatingDots } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'


import Moment from 'react-moment';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';




export default function Whatsapp(props) {

    const { t } = useTranslation();

    const [firstLoad, setFirstLoad] = useState(false)
    const [isLoadingWpp, setIsLoadingWpp] = useState(true);
    const { currentNumber, currentChannel, chatsNumbers, unread, profilePic, wppAbout,demoMode } = props

    const [animate, setAnimate] = useState(true)

    const [chats, setChats] = useState([])

    useEffect(() => {

        const getRandomChats = async () => {
            const chatsAux = await generateRandomChats(chatsNumbers)
            setChats(chatsAux)
        }
        getRandomChats()


    }, [])

    useEffect(() => {

        if (currentChannel === 'Whatsapp') {

            if (!firstLoad) {
                setIsLoadingWpp(true)
                setTimeout(() => { setIsLoadingWpp(false) }, 4000);
                setFirstLoad(true)
            }

            setTimeout(() => { setAnimate(false) }, 20000);//Desp de ese tiempo, no se anima mas. Se carga todo bluroeado
        }

    }, [currentChannel])

    const getCircularReveal = (unread) => {
        if (unread === 0) return null
        return (
            <div className='ml-2 font-weight-bold bg-light mt-1' style={{ color: "#008069", borderRadius: "50%", width: "13px", height: "13px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ fontSize: "8px" }}>{unread}</span>
            </div>
        )
    }

    if (currentChannel != 'Whatsapp') return null

    if (isLoadingWpp) return (
        <div className="rounded" style={{ backgroundColor: "rgba(37, 211, 102, 1)", height:"558px", width:"278px" }} >
            <div className='text-center ' style={{ padding: "30px"}}>
                <Flip><i className='fa fa-whatsapp fa-brands' style={{ fontSize: "90px",marginTop:"50%"  }} /></Flip>
                <Fade><div className='text-center small '  ><i className='fa fa-spinner fa-spin fa-2x mr-1' /> {t('Whatsapp.cargando')} </div></Fade>
            </div>
        </div>
    );
    return (

        < >
            {/* HEADER**************/}
            <div className='w-100 rounded-top' style={{ backgroundColor: "#008069" }}>
                <div className='d-flex justify-content-between pt-2 px-1 py-3'>
                    <div className='text-left ml-1'>
                        <div className=' font-weight-bold ml-2' style={{ fontSize: "14px" }}><img src={profilePic} className="rounded-circle" style={{ width: "30px", height: "30px" }} />
                            {wppAbout ?
                                <span className='ml-1 mt-3' style={{fontSize:"0.7em",position:"absolute"}}>{wppAbout.substring(0,35)}...</span>
                                // <span>𝓐𝓶𝓸𝓻 𝓭𝓮 𝓶𝓲 𝓿𝓲𝓭𝓪🤍🦁</span>
                                :
                                <span className='ml-1'>WhatsApp</span>}
                        </div>

                    </div>
                    <div className='d-flex justify-content-between '>
                        <i className='fa fa-camera mx-3' />
                        <i className='fa fa-search' />
                        <i className='fa fa-ellipsis-v mx-3' />
                    </div>
                </div>
                <div className='d-flex justify-content-between px-1 small font-weight-bold pb-1'>
                    <div className='d-flex' style={{ borderBottom: "3px solid #fff" }}><i className='fa fa-users mx-2' /> Chats  {getCircularReveal(unread)}  </div>
                    <div className='d-flex' style={{ fontSize: "11px" }}>{t('Whatsapp.status')} {getCircularReveal(getRandomNumber(0, 0))}</div>
                    <div className='d-flex' style={{ fontSize: "11px" }}>{t('Whatsapp.llamadas')} {getCircularReveal(getRandomNumber(0, 3))}</div>
                </div>
            </div>
            {/* MENSAJESSSS */}
            <div className="container bg-light text-dark text-left p-0" style={{ maxHeight: "470px", overflowY: "auto", borderRadius: "0 0 10px 10px", width:"278px" }}>
                {chats.map((ch, index) => (
                    <WtspChatItem key={index} index={index} ch={ch} animate={animate} demoMode={demoMode} />
                ))

                }
            </div>
        </>
    )
}


function WtspChatItem(props) {
    const { t } = useTranslation();

    const { ch, index, animate,demoMode } = props

    const [isLoading, setIsLoading] = useState(false);
    const [cssBlur, setCssBlur] = useState("")

    useEffect(() => {

        let blur=""
        if(demoMode)blur="divBlur"

        if (!animate) {
            setCssBlur(blur)
        } else {
            setIsLoading(true)
            setTimeout(() => {
                setIsLoading(false)

                setCssBlur(true)
                setTimeout(() => { setCssBlur(blur) }, 40);

            }, getRandomNumber(1000, 10000));
        }
    }, [])

    function TypeStyle(props) {
        const { type, isUnread } = props
        if (isUnread) return null

        //Entregado
        if (type === 1 || type === 2) return <span className='text-secondary' style={{ fontSize: "10px" }}><i className='fa fa-check ' /><i className='fa fa-check' /></span>
        //Entregado y LEIDO
        if (type === 3 || type === 4) return <span className=' text-primary ' style={{ fontSize: "10px" }}><i className='fa fa-check' /><i className='fa fa-check' /></span>

        return null
    }


    if (isLoading) return (
        <div className='mt-4 text-center d-flex px-2'>
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
            />
            {/* <div className='text-center small text-dark ml-1 ' ><span className='divBlur'>{ch.conntactName}</span></div> */}
            <div className='text-center small text-dark ml-1 ' >{t('Whatsapp.cargandoChat')}</div>
        </div>
    )

    const getUnreadColor = (unread) => {
        if (unread) return "#03d562"
        return "#4c4f50"
    }
    const isValidDate = (fechaString) => {
        const fecha = new Date(fechaString);
        return !isNaN(fecha) && fecha.toString() !== 'Invalid Date';
    }



    return (
        <div key={index} className={`d-flex justify-content-between my-2 ${cssBlur} px-2`} >
            <div className='d-flex ' style={{fontSize:"0.8em"}}>

                <img src={ch.picture} className="rounded-circle" style={{ width: "40px", height: "40px" }} />
                <div >
                    <div className='mx-2 font-weight-bold' >{ch.conntactName} </div>
                    <div className='mx-2' style={{ color: "#4c4f50"}}><TypeStyle type={ch.type} isUnread={ch.unread} /> {ch.snippet.substring(0, 20) + "..."}</div>
                </div>
            </div>

            <div className='small ' >

                <div style={{ color: getUnreadColor(ch.unread), fontSize:"0.75em" }}>
                    {isValidDate(ch.lastMessage.toString()) ?
                        <Moment date={ch.lastMessage} format="L" />
                        :
                        <div>{ch.lastMessage.toString()}</div>

                    }
                </div>

                {ch.unread &&
                    <div className='d-flex justify-content-center mt-1'>
                        <div className=' ml-2 font-weight-bold' style={{fontSize:"0.8em", paddingTop:"1px", color: "#fff", backgroundColor: "#03d562", borderRadius: "50%", width: "18px", height: "18px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {getRandomNumber(1, 6)}
                        </div>
                    </div>
                }
            </div>

        </div>
    )

}