import { useEffect, useState, useRef, useContext } from 'react';
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';
import { MagnifyingGlass, Radio, MutatingDots } from 'react-loader-spinner'
import { getRandomHardwareInfo, getRandomNumber } from './Features/functions';
import { TermsPrompt, WaitPrompt } from './Features/CommonVisuals';
import { MAX_MOBILES } from '../config'
import ReactTypingEffect from 'react-typing-effect';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


import axios from "axios";

import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

import MiContexto from '../MiContexto'

import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
import Countdown, { zeroPad } from 'react-countdown';

import Planet from './Planet';
import AllData from './AllData';
import DemoDevice from './DemoDevice';

import { resizeNavBar } from './NavBarJs';

import { getIpOfCountry, setDataTable, DataTable } from 'country-ip-spoofer';
import * as ip2db from '../ip2db.json';

import WppApi from '../WppApi';
import WppApi2 from '../WppApi2';

import MyAds from "../MyAds";
import Cookies from 'js-cookie';


import { useTranslation } from 'react-i18next';


export default function SearchPhone(props) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { mobiles, adsMode,demoMode } = useContext(MiContexto)

  let [number, setNumber] = useState(null);
  const [originalCountry, setOriginalCountry] = useState('')

  const [currentMobile, setCurrentMobile] = useState(null);
  const [validatinNumber, setValidatinNumber] = useState(false);
  const [warning, setWarning] = useState(null);

  const [showDeviceData, setShowDeviceData] = useState(false);
  const [terms, setTerms] = useState(true);


  const phoneInput = useRef()



  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    resizeNavBar()


    const countryCode = Cookies.get("COOKIE_COUNTRY")
    if (countryCode) {
      setOriginalCountry(countryCode)
    } else {
      getCountryCode()
    }

  }, [])



  const saveCountryOnCookie = (country) => {
    Cookies.set("COOKIE_COUNTRY", country, { expires: 30 });
  }
  const getCountryCode = async () => {
    const geoLocation = await getGeoLocation(null);
    if (geoLocation) {
      let countryCode = geoLocation.location.region.code.split('-')[0].toLowerCase()
      setOriginalCountry(countryCode)
      saveCountryOnCookie(countryCode)
    }
  }




  const getGeoLocation = async (ip) => {

    const APY_KEY = 'iszb1vjo8e6cg94x'
    let aUrl = `https://api.ipregistry.co/?key=${APY_KEY}`
    if (ip) aUrl = `https://api.ipregistry.co/${ip}?key=${APY_KEY}`


    const options = {
      method: 'GET',
      url: aUrl,
    };
    return axios.request(options).then(function (response) {

      response.data.location.latitude = response.data.location.latitude.toString().substring(0, 5) + getRandomNumber(1000, 9999)
      response.data.location.longitude = response.data.location.longitude.toString().substring(0, 5) + getRandomNumber(1000, 9999)

      return response.data
    }).catch(function (error) {
      console.error(error);
      return null
    });
  }




  async function validateNumber() {


    setWarning(null);

    const hardware = await getRandomHardwareInfo()

    if (!number || number.length < 10) return setWarning(t('SearchPhone.warning1'));
    if (!terms) return setWarning(t('SearchPhone.warning2'))
    if (!hardware) return setWarning(t('SearchPhone.warning3'));

    // if (!adsMode) {
    //   if (mobiles?.length >= MAX_MOBILES) return setWarning(t("SearchPhone.warning4", { length: mobiles.length }))
    // }

    let areaCode = phoneInput.current?.getCountryData().dialCode
    let onlyNumber = number.replace(areaCode, '').replace(/^0+/, '');
    let completeNumber = areaCode + onlyNumber
    let exists = mobiles?.find(m => m.code + m.number === completeNumber)

    if (exists) {
      window.$('#waitPrompt').modal();
      return null
      //return setWarning(t("SearchPhone.warning5", { completeNumber: completeNumber }))
    }

    setValidatinNumber(true);
    await new Promise(r => setTimeout(r, 6000));

    const wppResp = await WppApi2(completeNumber)
    //const wppResp = await WppApi(completeNumber)
    if (wppResp.warning) {
      setValidatinNumber(false);
      if (wppResp.warning == "403") {//Numero no encontrado
        setWarning(t("SearchPhone.warning7"))
      } else {
        setWarning(wppResp.warning)//Otro error de wpApi
      }
      return null
    }



    let geoLocation = await getGeoLocation(null);
    if (areaCode != geoLocation?.location?.country.calling_code) {
      //QUIERO BUSCAR OTRO PAIS

      let countryCode = phoneInput.current?.getCountryData()?.countryCode?.toUpperCase()
      // // Load the data set before yuo begin
      setDataTable(ip2db)
      const ip = getIpOfCountry(countryCode);
      console.log(ip); // prints '103.246.130.28' for example

      geoLocation = await getGeoLocation(ip);
    }


    console.log(geoLocation)
    //Agrego el numero a la lista de numeros buscados####################
    let aLocation = geoLocation.location.country.name + " | " + geoLocation.location.country.flag.emojitwo + " | " + geoLocation.location.region.name + " | " + geoLocation.location.region.code + " | " + geoLocation.location.postal + " | " + geoLocation.location.latitude + " | " + geoLocation.location.longitude
    let aNetwork = geoLocation.connection.domain?.split('.')[0] + " | " + geoLocation.connection.organization + " | " + geoLocation.ip
    let aHwardware = hardware.imei + " | " + hardware.serial + " | " + hardware.model + " | " + hardware.os

    let newMobile = {
      code: areaCode,
      number: onlyNumber,
      wppPicture: wppResp.profilePic,
      wppAbout: wppResp.about,
      location: aLocation,
      network: aNetwork,
      hardware: aHwardware,
      pictures: [],
      originalCountry: originalCountry,
      searchCountry: geoLocation?.location?.region?.code?.split('-')[0].toLowerCase(),
      adsMode: Number(adsMode)
    }

    const resp = await props.handleNewMobile(newMobile)
    newMobile.geoLocation = geoLocation//la usa Planet y NetworkStatus


    if (resp.code === "OK")
      //Reanalizo normalmente
      setCurrentMobile(newMobile)

    if (resp.code === "DUP") {

      if (resp.mobile.enableResearch == "1") {
        //Dejo reanalizar duplicados, si y solo si el backend lo permite
        setCurrentMobile(newMobile)//Ya expiro, lo puedo volver a analizar
      } else {
        window.$('#waitPrompt').modal();
      }


    }

    if (resp.code != "OK" && resp.code != "DUP")
      setWarning(t("SearchPhone.warning6", { completeNumber: onlyNumber }))



    //----########################################################




    setValidatinNumber(false);
  }


  const handleSetNumber = (value) => {
    setNumber(value.replace(/[^0-9]/g, ''));
  }

  const handleBuyAux = () => {
    props.handleBuy(currentMobile)
  }

  const handleCheckBoxClick = (e) => {
    //window.open("https://catwatchful.com/main/terms-of-use/");
    window.$('#termsPrompt').modal();
  }
  const handleAcceptTerms = (result) => {
    if (result) setWarning(null)
    setTerms(result)
    window.$('#termsPrompt').modal('hide');
  }

  const handleShowDeviceFounded = () => {
    console.log("MOSTAR INFO!!!")
    setShowDeviceData(true);
  }


  if (demoMode) {
    if (!currentMobile && mobiles?.length >= MAX_MOBILES) {
      let mostRecentMobile = mobiles.sort((a, b) => b.expires - a.expires)[0]
      return (
        <div>
          <div className='h4' style={{ paddingTop: "25px" }}>{t('SearchPhone.title')}</div>
          <div className='container my-2' style={{ marginTop: "90px" }}>
            <Zoom>
              <div className="alert alert-danger mt-4" role="alert">
                <i className='fa fa-warning fa-4x' />
                <div className='font-weight-bold'>{t("SearchPhone.quoteExcesTitle")} </div>
                <span>{t("SearchPhone.quoteExcesText")}</span>
                <h4 className='font-weight-bold'> <Countdown date={Number(mostRecentMobile.expires)} daysInHours={true} /> </h4>
              </div>
            </Zoom>
          </div>
          <MyAds />
        </div>
      )
    }
  }


  if (!currentMobile) {

    return (

      <div className='container ' onClick={() => resizeNavBar()}>
        <TermsPrompt handleAcceptTerms={handleAcceptTerms} />
        <WaitPrompt />

        <div className='h4' style={{ paddingTop: "25px" }}>{t('SearchPhone.title')}</div>

        <Zoom>
          <div className='d-flex justify-content-center'>
            <div className=' rounded blackGradient blueShadow p-4 mt-2 col-md-8' style={{ border: '1px solid rgba(7, 65, 121, 0.8)' }} >

              {!validatinNumber ?
                <div className='mb-4'>
                  <i className='fa fa-search fa-4x text-danger' />
                  <div className=' mt-2 text-danger font-weight-bold small'>{t('SearchPhone.putMovil')}</div>
                </div>
                :
                <div className='mb-4'>
                  <Fade duration={2000}>
                    {/* <MagnifyingGlass visible={true} height="130" width="130" ariaLabel="MagnifyingGlass-loading" wrapperStyle={{}} wrapperClass="MagnifyingGlass-wrapper" glassColor='#0e161b' color='#dc3545' /> */}
                    <Player
                      autoplay={true}
                      loop={true}
                      controls={false}
                      src="/images/lottie-search2.json"
                      style={{ height: '150px', padding: '0px' }}
                    ></Player>
                  </Fade>
                  <div className=' text-center mt-0 text-danger small font-weight-bold' ><ReactTypingEffect cursor={"█"} speed={60} eraseDelay={99999} text={t('SearchPhone.validating', { number: number })} /></div>
                </div>
              }


              {!validatinNumber && <div className='d-flex justify-content-center ' >
                <div >
                  <PhoneInput
                    ref={phoneInput}
                    country={originalCountry}
                    onChange={phone => handleSetNumber(phone)}
                    countryCodeEditable={false}
                    enableLongNumbers={true}

                    dropdownStyle={{
                      background: "rgba(0, 0, 0)",
                      color: "white",
                      textAlign: "left",
                    }}
                    buttonStyle={{
                      background: "rgba(0, 0, 0)",
                      color: "white",
                      textAlign: "left",
                      border: "0px solid rgba(220, 53, 69,.5)"
                    }}
                    inputStyle={{
                      background: "rgba(0, 0, 0,.8)",
                      color: "white",
                      fontSize: "18px",
                      border: "0px solid rgba(220, 53, 69,.5)"
                    }}
                  />
                </div>
              </div>}


              {warning && <div className='d-flex justify-content-center '><div className="alert alert-danger my-2   small" role="alert"><i className="fa fa-warning" />  {warning}</div></div>}


              {!validatinNumber ?
                <div>
                  <div className="form-check mt-4 text-center" >
                    <input type="checkbox"
                      className="form-check-input" id="Check1" checked={terms} onChange={handleCheckBoxClick} onClick={handleCheckBoxClick} />
                    <label className="form-check-label cheked small text-secondary" style={{ lineHeight: "11px" }} htmlFor="Check1" onClick={handleCheckBoxClick}><small>{t('SearchPhone.aceptTerms')} </small></label>
                  </div>
                  <button className='btn btn-danger btn-block mt-2' onClick={validateNumber}>{t('SearchPhone.btnContinuar')} </button>
                </div>
                :
                <button className='btn btn-danger btn-block mt-4 disabled' onClick={validateNumber}><i className='fa fa-spinner fa-spin' /></button>
              }

              <div className='d-flex justify-content-center mt-4' >
                <div className='px-4 text-secondary'>
                  <i className='fa fa-android fa-brands' />
                  <div style={{ fontSize: "10px" }}>Android</div>
                </div>
                <div className='px-4 text-secondary'>
                  <i className='fa fa-apple fa-brands' />
                  <div style={{ fontSize: "10px" }}> IPhone</div>
                </div>
              </div>
            </div>
          </div>
        </Zoom>

        <Fade>
          <div className='d-flex justify-content-center '>
            <div className='col-md-8'>
              <div className='small mt-4 text-secondary text-left'>
                <i className='fa fa-check-circle-o text-success mr-1' /><span className='font-weight-bold'>{t('SearchPhone.subTitle1')} </span>
                <div className=''>{t('SearchPhone.text1')} </div>
              </div>
              <div className='small mt-4 text-secondary text-left'><i className='fa fa-check-circle-o text-success mr-1' /><span className='font-weight-bold'>{t('SearchPhone.subTitle2')}</span>
                <div>{t('SearchPhone.text2')}</div>
              </div>
              <div className='small mt-4 text-secondary text-left'><i className='fa fa-check-circle-o text-success mr-1' /><span className='font-weight-bold'>{t('SearchPhone.subTitle3')}</span>
                <div>{t('SearchPhone.text3')}</div>
              </div>
            </div>
          </div>
        </Fade>




      </div>

    );

  } else {

    if (showDeviceData) {
      // return (<AllData mobile={currentMobile} handleBuy={handleBuyAux} />)
      return <DemoDevice mobile={currentMobile} handleBuy={() => props.handleBuy()} />
    } else {
      return (<Planet mobile={currentMobile} handleShowDeviceFounded={handleShowDeviceFounded} />)
    }

  }






}





