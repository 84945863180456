import { names, surnames, nicks, snippets, getProfilePictures,possibleModels,possibleOs } from './data.js'


export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomBoolean(probability=0.5) {
  return Math.random() >= probability;
}

export function getRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i <= length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomEmail() {
  const providers = ['gmail', 'yahoo', 'outlook', 'hotmail', 'icloud'];
  const accounts= ['com', 'es', 'net', 'org', 'info', 'biz', 'eu', 'cat', 'gob', 'edu', 'gov', 'int', 'mil', 'arpa', 'aero', 'coop', 'museum', 'name', 'pro', 'tel', 'travel', 'xxx', 'id', 'jobs', 'mobi', 'post', 'geo', 'asia', 'cat', 'tel', 'travel', 'xxx', 'id', 'jobs', 'mobi', 'post', 'geo', 'asia']
  const usersNames=['juan','pepe','maria','luis','carlos','jose','paco','lola','ana','laura','marta','pilar','rosa','carmen','david','raul','jorge','alberto','javier','sergio','daniel','josep','francesc','joan','pere','carles','xavier','jordi','miquel','miguel','angel','antonio','manuel','francisco','jose','david','raul','jorge','alberto','javier','sergio','daniel','josep','francesc','joan','pere','carles','xavier','jordi','miquel','miguel','angel','antonio','manuel','francisco','jose']

  const randomEmail=usersNames[getRandomNumber(0, usersNames.length-1)] + getRandomNumber(20000, 99999) + '@' + providers[getRandomNumber(0, providers.length-1)] + '.' + accounts[getRandomNumber(0, accounts.length-1)]

  return randomEmail;
}




export const randomizeArray = (array) => {
  let currentIndex = array.length, randomIndex;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

const sortArrayByDate = (array, key, order = 'asc') => {

  return array.sort(function (a, b) {
    var dateA = new Date(a[key]), dateB = new Date(b[key]);
    if (order === 'asc') {
      return dateA - dateB;
    }
    if (order === 'desc') {
      return dateB - dateA;
    }
  });
};


export const getRandomHardwareInfo = () => {
    
  let imei = getRandomNumber(100000000000000, 999999999999999)
  let serial = getRandomString(13)

  let model=possibleModels[getRandomNumber(0, possibleModels.length-1)]
  let os=possibleOs[getRandomNumber(0, possibleOs.length-1)]

  return { imei: imei, serial: serial, model: model, os: os }
}



export const generateRandomChats = async (size,fullname=false) => {
  let chatsAux = []
  let contacts = await getRandomContacts(size)

  for (let i = 0; i < contacts.length; i++) {
    let contact = contacts[i]

    let newChat = {
      conntactName: !fullname?getRandomStyleName(contact.name.nick, contact.name.last):contact.name.first+" "+ contact.name.last,
      picture: contact.picture.thumbnail,
      conntactNumber: contact.phone,
      snippet: contact.snippet,
      lastMessage: getRandomDate(),
      //unreadProbability: getRandomNumber(1, 5),
      type: getRandomNumber(1, 6)//enviado/recibido/visto
    }
    chatsAux.push(newChat)
  }

  sortArrayByDate(chatsAux, 'lastMessage', 'desc')


 let firstChats=[]

 firstChats=chatsAux.slice(0, getRandomNumber(6, 10))
 for(let i=0;i<firstChats.length;i++){
   firstChats[i].lastMessage="Ayer"
 }


firstChats=chatsAux.slice(0, getRandomNumber(3, 6))
  for(let i=0;i<firstChats.length;i++){
    firstChats[i].lastMessage=getRandomTime()
    firstChats[i].unread=getRandomBoolean(0.7)  
  }



  
  return chatsAux
}




export const getRandomStyleName = (name, surname) => {

  const aRandom = getRandomNumber(1, 4)
  if (aRandom === 1) return name
  if (aRandom === 2) return surname
  if (aRandom === 3) return name + " " + surname
  if (aRandom === 4) return name.toUpperCase()
  //if (aRandom === 5) return surname.toUpperCase()

}

export const getRandomDate = () => {
  // Obtener la fecha actual
  const currentDate = new Date();

  // Obtener una fecha aleatoria entre 1 y 30 días hacia atrás
  const randomDays = Math.floor(Math.random() * 15) + 1;

  const randomDate = new Date(currentDate);
  randomDate.setDate(currentDate.getDate() - randomDays);
  return randomDate;
}

export const getRandomTime = () => {
  const horas = Math.floor(Math.random() * 24); // Obtener una hora aleatoria entre 0 y 23
  const minutos = Math.floor(Math.random() * 60); // Obtener minutos aleatorios entre 0 y 59

  // Formatear la hora en formato de 24 horas
  const horaFormateada = `${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}`;

  return horaFormateada;
}


export const getRandomContacts = async (size) => {
  let contacts = []

  const getRandomName = () => {
    let randomIndex = Math.floor(Math.random() * names.length);
    let searchEnd = false
    while (searchEnd === false) {
      if (contacts.find(contact => contact.name.first === names[randomIndex]) === undefined) {
        searchEnd = true
        return names[randomIndex]
      } else {
        randomIndex = Math.floor(Math.random() * names.length);
      }
    }


  }

  const getRandomSurname = () => {

    let randomIndex = Math.floor(Math.random() * surnames.length);
    let searchEnd = false
    while (searchEnd === false) {
      if (contacts.find(contact => contact.name.last === surnames[randomIndex]) === undefined) {
        searchEnd = true
        return surnames[randomIndex]
      } else {
        randomIndex = Math.floor(Math.random() * surnames.length);
      }
    }

  }


  const getRandomNickname = () => {
    let randomIndex = Math.floor(Math.random() * nicks.length);
    let searchEnd = false
    while (searchEnd === false) {
      if (contacts.find(contact => contact.name.first === nicks[randomIndex]) === undefined) {
        searchEnd = true
        return nicks[randomIndex]
      } else {
        randomIndex = Math.floor(Math.random() * nicks.length);
      }
    }

  }

  const getRandomSnippet = () => {
    let randomIndex = Math.floor(Math.random() * snippets.length);
    let searchEnd = false
    while (searchEnd === false) {
      if (contacts.find(contact => contact.snippet === snippets[randomIndex]) === undefined) {
        searchEnd = true
        return snippets[randomIndex]
      } else {
        randomIndex = Math.floor(Math.random() * snippets.length);
      }
    }
  }

  const getRandomPhone = () => {
    let phone = ""
    for (let i = 0; i < 10; i++) {
      phone += getRandomNumber(0, 9)
    }
    return phone
  }

  const getRandomPicture = () => {
    let imagesAux = getProfilePictures()
    let searchEnd = false
    while (searchEnd === false) {
      let randomIndex = Math.floor(Math.random() * imagesAux.length);
      let image = imagesAux[randomIndex]

      if (contacts.find(contact => contact.picture.thumbnail === image) === undefined) {
        searchEnd = true
        return image
      }
    }
  }


  for (let i = 0; i < size; i++) {
    let newContact = {
      name: {
        first: getRandomName(),
        last: getRandomSurname(),
        nick: getRandomNickname(),
      },
      picture: {
        thumbnail: getRandomPicture()
      },
      phone: getRandomPhone(),
      snippet: getRandomSnippet(),
    }

    contacts.push(newContact)
  }

  return contacts

}

export const sleepTime = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

export const  playAudio = (fileName) => {
  let audio = new Audio('./images/' + fileName + '.mp3');
  audio.play();
}

