import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MiContexto from '../MiContexto'
import NavBar from "./NavBar";
import MyAds from "../MyAds";

export default function Header(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { adsMode } = useContext(MiContexto)

    // const user = useContext(MiContexto).user
    // const userData = useContext(MiContexto).userData
    const currentMobile = useContext(MiContexto).currentMobile
    const mobiles = useContext(MiContexto).mobiles

    const [salesNotify, setSalesNotify] = useState([])


    const onLogoClick = () => {
        navigate('/dashboard')
    }

    return (

        <React.Fragment>



            {/* HEAD */}
            <div className="d-flex justify-content-between  blackGradient fixed-top pb-1" style={{ height: "44px", zIndex: "99999", position: "absolute"  }}>

                <NavBar />

                <div className="d-flex mt-2" onClick={onLogoClick}>
                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} className="img-responsive" width="35px" />
                    <img style={{ marginLeft: "5px", marginTop: "14px" }} src={process.env.PUBLIC_URL + "/images/logotext.png"} className="img-responsive" height="10px" />
                </div>

                <div className="mt-2 mr-1">
                    {/* ACTIVE SECTION ****************************/}

                    <div className='d-flex justify-content-center text-secondary mr-2 blue' style={{ fontSize: "10px" }}>
                        <div >
                            <div className="text-left font-weight-bold">{t('Header.estado')}: <span className='text-success ml-1'>{t('Header.activo')} <i className='fa fa-check' /></span></div>
                            <div className="text-left font-weight-bold">{t('Header.redRastreo')}: <span className='text-success ml-1'>{t('Header.global')} <i className='fa fa-globe' /></span> </div>
                        </div>
                    </div>



                    {/* <Link to="dashboard"> <img src={"./images/logo.svg"} className="rounded-circle" style={{ width: "30px", height: "30px" }} alt="Avatar" /></Link> */}
                    {/* <Link to="dashboard"><span className="p-2 text-light" ><i className="fa fa-user mr-2"/>usuario</span></Link> */}

                    {/* <span onClick={props.handleLogout} className=""><i className="fa fa-sign-out mr-1 p-0 " /></span> */}

                    {mobiles?.length > 0 && <div className="text-light ml-1 pb-1" style={{ fontSize: "14px" }} >
                        {/* <Link to="dashboard"><span className="mr-3 badge badge-success text-dark" >Móviles: {mobiles?.length}</span></Link> */}
                        <Link to="dashboard"><span className="badge badge-warning d-block" ><i className="fa fa-warning mr-1" />{t('Header.pendientes')}: {mobiles?.length}</span></Link>
                        {salesNotify.length > 0 && <Link to="sales"><span className="badge badge-danger ml-1 mb-2">{t('Header.new')}: {salesNotify.length}</span></Link>}
                    </div>}

                </div>

                {/* *********************************** */}

            </div>

        </React.Fragment>

    )
}