import { useEffect, useState, CSSProperties } from 'react';
import { InfinitySpin } from 'react-loader-spinner'
import { Radio } from 'react-loader-spinner'
import { getRandomNumber, getRandomBoolean } from './functions';
import { wifiNames } from '../Features/data'
import { useTranslation } from 'react-i18next';


export default function DeviceInfo(props) {

    const { t } = useTranslation();

    const { currentNumber, hardware, network } = props

    const [isLoading, setIsLoading] = useState(false);
    const [aHardware, setHardware] = useState({ imei: "", serial: "", model: "", os: "" })
    const [aNetwork, setNetwork] = useState({ domain: "", organization: "", ip: "" })


    useEffect(() => {

        if (!hardware) return null
        if (!network) return null

        // setIsLoading(true)
        // setTimeout(() => { setIsLoading(false) }, getRandomNumber(1000, 10000));
        let imei = hardware.split("|")[0]
        let serial = hardware.split("|")[1]
        let model = hardware.split("|")[2]
        let os = hardware.split("|")[3]
        setHardware({ imei: imei, serial: serial, model: model, os: os })


        let domain = network.split("|")[0]
        let organization = network.split("|")[1]
        let ip = network.split("|")[2]
        setNetwork({ domain: domain, organization: organization, ip: ip })



    }, [hardware])


    if (isLoading) return (
        <div className='mt-0 text-center'>

            <InfinitySpin
                width='200'
                color="#00C3F7"
            />
            <div className='text-center small' style={{ color: "#00C3F7" }}>{t('DeviceInfo.cargandoInfo')} {currentNumber}</div>

        </div>
    );


    let batteryLevel = getRandomNumber(5, 100)
    let connectedWifi = getRandomBoolean()
    let gpsEnabled = getRandomBoolean()
    let signalLevel = getRandomNumber(5, 100)
    let soundLevel = getRandomNumber(1, 3)
    let chargerState = getRandomNumber(0, 2)
    let wifiName = wifiNames[getRandomNumber(0, wifiNames.length - 1)]
    let wifiQuality = getRandomNumber(0, 5) + "|5"

    const status = { batteryLevel: batteryLevel, connectedWifi: connectedWifi, gpsEnabled: gpsEnabled, signalLevel: signalLevel, soundLevel: soundLevel, chargerState: chargerState, wifiName: wifiName, wifiQuality: wifiQuality }


    if (status) {

        //SIGNAL LEVEL
        let signalLevel = status.signalLevel
        let signalLevelIco = ""
        if (signalLevel >= 0 && signalLevel <= 10) {
            signalLevelIco = "fa fa-signal text-danger mr-1"
        } else if (signalLevel >= 11 && signalLevel <= 30) {
            signalLevelIco = "fa fa-signal text-danger mr-1"
        } else if (signalLevel >= 31 && signalLevel <= 50) {
            signalLevelIco = "fa fa-signal text-warning mr-1"
        } else if (signalLevel >= 51 && signalLevel <= 80) {
            signalLevelIco = "fa fa-signal text-info mr-1"
        } else if (signalLevel >= 81 && signalLevel <= 100) {
            signalLevelIco = "fa fa-signal text-success mr-1"
        }


        //CONECTION TYPE
        let connectionType = t('DeviceInfo.mobileData')
        let connectionTypeIco = "fa fa-exchange text-primary mr-1"
        if (status.connectedWifi == true) { connectionType = "Wifi " + status.wifiName; connectionTypeIco = "fa fa-wifi text-success mr-1" }



        //WIFI QUALITY
        const getWifiQualityHtml = () => {
            const partitions = status.wifiQuality.split("|")
            if (partitions.length > 1) {
                let wifiLevel = partitions[0]
                let color = ""
                if (wifiLevel >= 0 && wifiLevel <= 2) {
                    color = "text-danger"
                } else if (wifiLevel == 3) {
                    color = "text-warning"
                } else if (wifiLevel >= 4) {
                    color = "text-success"
                }
                return <span className={color + " ml-2"}> {wifiLevel}/5</span>
            } else {
                return null
            }
        }


        //SOUND LEVEL
        let soundLevel = ""
        let soundLevelIco = ""
        if (status.soundLevel == 1) { soundLevel = t('DeviceInfo.silencio'); soundLevelIco = "fa fa-volume-off mr-1 text-danger" }
        if (status.soundLevel == 2) { soundLevel = t('DeviceInfo.vibra'); soundLevelIco = "fa fa-bell-o mr-1 text-warning" }
        if (status.soundLevel == 3) { soundLevel = t('DeviceInfo.sonido'); soundLevelIco = "fa fa-volume-up mr-1 text-success" }

        //CHARGER STATE
        let chargerState = ""
        let chargerStateIco = ""
        if (status.chargerState == 0) { chargerState = t('DeviceInfo.sinConectar'); chargerStateIco = "fa fa-plug mr-1 text-info" }
        if (status.chargerState == 1) { chargerState = t('DeviceInfo.cargando'); chargerStateIco = "fa fa-bolt mr-1 text-warning" }
        if (status.chargerState == 2) { chargerState = t('DeviceInfo.usb'); chargerStateIco = "fa fa-usb mr-1 text-success" }


        //GPS STATUS
        let gpsStatus = t('DeviceInfo.gpsOff')
        let gpsIco = "fa fa-ban mr-1 text-danger"
        if (status.gpsEnabled == true) { gpsStatus = t('DeviceInfo.gpsOn'); gpsIco = "fa fa-map-marker text-success" }




        //BATTERY LEVEL
        let batteryLevel = status.batteryLevel
        let batteryIco = ""
        if (batteryLevel >= 0 && batteryLevel <= 10) {
            batteryIco = "fa fa-battery-empty text-danger mr-1"
        } else if (batteryLevel >= 11 && batteryLevel <= 30) {
            batteryIco = "fa fa-battery-quarter text-danger mr-1"
        } else if (batteryLevel >= 31 && batteryLevel <= 50) {
            batteryIco = "fa fa-battery-half text-warning mr-1"
        } else if (batteryLevel >= 51 && batteryLevel <= 80) {
            batteryIco = "fa fa-battery-three-quarters text-info mr-1"
        } else if (batteryLevel >= 81 && batteryLevel <= 100) {
            batteryIco = "fa fa-battery-full text-success mr-1"
        }



        //#B0A8B9 
        //style={{ opacity: 1.0, backgroundColor: "#296073" }}
        return (
            <div className="mt-0 pt-0 rounded-lg text-light px-2">
                {/* <FeatureTitle ftName='Estado del dispositivo' icon='fa-mobile' /> */}

                <div className="d-flex justify-content-center">
                    <span className="text-success font-weight-bold pt-2">{t('DeviceInfo.conectado')}</span>
                    <span className=''><Radio visible={true} height="30" width="30" ariaLabel="radio-loading" wrapperStyle={{}} wrapperClass="radio-wrapper" colors={["#28a745", "#6c757d", "#6c757d"]} /></span>
                </div>

                {/* <span className='small text-success'>IP: {geoLocation.ip.split(".")[0] + "." + geoLocation.ip.split(".")[1] + "." + geoLocation.ip.split(".")[2] + "." + getRandomNumber(1, 255)}</span> */}


                <div className='small  fondoTransOscuro rounded py-2 px-3' style={{border:'1px solid rgba(150, 255, 210,.2)'}}>

                    <div className='d-flex justify-content-center '>
                        <div className='div mx-3 text-left small'>
                            <div className='text-secondary'>IMEI: {aHardware.imei}</div>
                            <div className='text-secondary'>SERIAL: {aHardware.serial}</div>
                            <div className='text-secondary text-uppercase'>IP: {aNetwork.ip}</div>
                        </div>
                        <div className='div text-left small'>
                            <div className='text-secondary text-uppercase'>OS: {aHardware.os}</div>
                            <div className='text-secondary'>{t('DeviceInfo.modelo')}: {aHardware.model}</div>
                            <div className='text-secondary text-uppercase'>{t('DeviceInfo.red')}: {aNetwork.domain}</div>
                        </div>
                    </div>



                    <hr className='py-0 my-1 bg-secondary' />


                    <div className="d-flex justify-content-between">
                        <span className="text-center"><i className={signalLevelIco} /><small>{t('DeviceInfo.signal')} {signalLevel}% </small></span >
                        <span className="text-center "><i className={batteryIco} /><small>{batteryLevel}%</small></span>
                        <span className="text-center"><i className={soundLevelIco} /><small>{soundLevel}</small></span>
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                        <span className="text-center"><i className={connectionTypeIco} /><small>{connectionType}</small>  {status.connectedWifi == 'true'}</span >
                        <span className="text-center "><i className={chargerStateIco} /><small>{chargerState}</small></span>
                        <span className="text-center"><i className={gpsIco} /><small> {gpsStatus}</small></span>
                    </div>

                </div>


            </div>

        )


    }



}