import { useEffect, useState, CSSProperties } from 'react';
import { Radio } from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { getRandomNumber, sleepTime, playAudio } from './functions';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { FeatureTitle } from './CommonVisuals'

import { useTranslation } from 'react-i18next';


export default function NetworkData(props) {

    const { t } = useTranslation();

    const { aLocation, currentNumber, aHardware, aNetwork, currentChannel } = props
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {

        const init = async () => {
            setIsLoading(true)
            await new Promise(r => setTimeout(r, getRandomNumber(3000, 6000)));
            setIsLoading(false)
            playAudio('location')
        }
        if (currentChannel == 'Ajustes') init()


    }, [currentChannel])


    if (currentChannel != 'Ajustes') return null


    if (isLoading) return (
        <div className='rounded' style={{ height: "559px", width: "278px", backgroundColor: "#000", paddingTop: "50%" }}>

            <Player
                autoplay={true}
                loop={true}
                controls={false}
                src="/images/lottie-loading1.json"
                style={{ height: '120px', width: '120px', padding: '0px' }}
            ></Player>
            <div className='text-center text-secondary small' >{t('NetworkData.cargando')} {currentNumber}</div>

        </div>
    );

    return (

        <div className='rounded' style={{ height: "559px", width: "278px", backgroundColor: "#000" }}>

            <FeatureTitle ftName={t('NetworkData.redActual')} icon='fa-cog' />

            <Fade>
                <div style={{ marginTop: "3px", fontSize: "1.0em" }} className='d-flex '>
                    <div className='px-3 text-left small'>
                        <div className='text-secondary'>IMEI: {aHardware.imei}</div>
                        <div className='text-secondary my-1'>SERIAL: {aHardware.serial}</div>
                        <div className='text-secondary text-uppercase'>IP: {aNetwork.ip}</div>
                        <div className='text-secondary text-uppercase my-1'>OS: {aHardware.os}</div>
                        <div className='text-secondary'>{t('DeviceInfo.modelo')}: {aHardware.model}</div>
                        <div className='text-secondary text-uppercase my-1'>{t('DeviceInfo.red')}: {aNetwork.domain}</div>
                        <div className='text-secondary text-uppercase my-1'>CODE: +{aNetwork.areaCode}</div>
                        <div className='text-secondary text-uppercase my-1'>{t('DeviceInfo.number')}: {currentNumber}</div>
                    </div>
                </div>

            </Fade>

            <hr className='bg-secondary' />



            <Fade delay={400}>
                <div className='mt-4 text-left px-3' style={{ fontSize: "0.8em", fontWeight: "300" }}>
                    <div className='mt-1'>ISP:<span className='text-info '> {aNetwork.organization}</span></div>
                    <div className='mt-1'>{t('NetworkData.red')}: <span className='text-info text-uppercase '>{aNetwork.domain}</span></div>

                    <div className='my-1'>IP: <span className='text-info  mr-1 '>{aNetwork.ip} </span> {t('NetworkData.tipo')}: <span className='text-info '>IPv4 </span></div>
                    <div>AREA: <span className='text-info mr-1'>+{aLocation.code}</span></div>
                    <div className='my-1'>CP: <span className='text-info mr-1'>{aLocation.cp}</span> <span className='text-info '>{aLocation.code}</span></div>

                    <div className='my-1'>{t('')}PAÍS: <span className='text-info mr-1'>{aLocation.country}</span> <img src={aLocation.flag} alt="flag" className='img-fluid' style={{ height: "20px" }} /></div>
                    <div>REGIÓN: <span className='text-info mr-1'>{aLocation.country}</span> <span className='text-info '>{aLocation.code}</span></div>

                </div>
            </Fade>

            <hr className='bg-secondary' />

            <Fade delay={800}>
                <div style={{ marginTop: "35px" }}>
                    <Radio
                        visible={true}
                        height="30"
                        width="30"
                        ariaLabel="radio-loading"
                        wrapperStyle={{}}
                        wrapperClass="radio-wrapper"
                        colors={["#28a745", "#6c757d", "#6c757d"]}
                    />
                    <span className='text-success' style={{ fontSize: "0.7em", marginTop: "10px" }}>{t('DeviceInfo.conectado')}</span>
                </div>
            </Fade>





        </div>

    )




}