import React, { useEffect, useState, useRef, useContext } from "react";
import { resizeNavBar } from '../Components/NavBarJs'
import MiContexto from '../MiContexto'
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

import { useTranslation } from 'react-i18next';
import Faq from "react-faq-component";
import MyAds from "../MyAds";


function Support(props) {

    const { t } = useTranslation();

    const { adsMode } = useContext(MiContexto)

    const user = useContext(MiContexto).user
    const mobiles = useContext(MiContexto).mobiles

    const [isLoading, setIsLoading] = useState(false)

    const [aEmail, setAEmail] = useState('')
    const [selectedQuery, setSelectedQuery] = useState('')
    const [queryComment, setQueryComment] = useState('')

    const [sendOk, setSendOk] = useState(false)

    const [warnings, setWarnings] = useState([]);


    const queryType = [
        { value: 0, label: t('Support.query1') },
        { value: 1, label: t('Support.query2') },
        { value: 2, label: t('Support.query3') },
        { value: 3, label: t('Support.query4') },
        { value: 4, label: t('Support.query5') },
        { value: 5, label: t('Support.query6') },
        { value: 6, label: t('Support.query7') },
        { value: 7, label: t('Support.query8') },
        { value: 8, label: t('Support.query9') },
    ]



    const data = {
        title: t('Support.FAQ'),
        rows: [
            {
                title: <div className="text-left">{t('Support.faq1Title')}</div>,
                content: <div className="text-left">{t('Support.faq1Text')}</div>,
            },
            {
                title: <div className="text-left">{t('Support.faq2Title')}</div>,
                content: <div className="text-left">{t('Support.faq2Text')}</div>,
            },
            {
                title: <div className="text-left">{t('Support.faq3Title')}</div>,
                content: <div className="text-left">{t('Support.faq3Text')}</div>,
            },
            {
                title: <div className="text-left">{t('Support.faq4Title')}</div>,
                content: <div className="text-left">{t('Support.faq4Text')}</div>,
            },
            {
                title: <div className="text-left">{t('Support.faq5Title')}</div>,
                content: <div className="text-left">{t('Support.faq5Text')}</div>,
            },
            {
                title: <div className="text-left">{t('Support.faq6Title')}</div>,
                content: <div className="text-left">{t('Support.faq6Text')}</div>,
            },
            {
                title: <div className="text-left">{t('Support.faq7Title')}</div>,
                content: <div className="text-left">{t('Support.faq7Text')}</div>,
            },
        ],
    };

    const styles = {
        bgColor: 'dark',
        arrowColor: "#fff",

        titleTextSize: '30px',
        titleTextColor: "#fff",

        rowTitleColor: "#fff",
        rowTitleTextSize: '15px',


        rowContentColor: 'grey',
        rowContentTextSize: '14px',
        rowContentPaddingTop: '0px',
        rowContentPaddingBottom: '0px',
        rowContentPaddingLeft: '0px',
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };


    const handleSelectChange = (e) => {
        const selectedQuery = e.target.value;
        console.log(selectedQuery)
        setSelectedQuery(selectedQuery);
    }


    const handleSendClick = async () => {
        let warnings = []
        if (selectedQuery === '') {
            warnings.push(t('Support.putQueryType'))
        }
        if (queryComment === '') {
            warnings.push(t('Support.putQueryText'))
        }
        if (aEmail.trim().length === 0) {
            warnings.push(t('Support.putEmail'))
        }
        if (warnings.length > 0) {
            setWarnings(warnings)
            return
        }

        console.log(mobiles[0])
        let query = {  type: selectedQuery, comment: queryComment, mobile: mobiles[0],email: aEmail, }
        setIsLoading(true)
        const resp = await props.handleSupportQuery(query)
        setIsLoading(false)

        if (resp == 'OK') {
            setSendOk(true)
        } else {
            setWarnings([resp])
        }

    }



    useEffect(() => {
        resizeNavBar()
    }, [user])



    return (
        <div className="pb-4 container  " onClick={() => resizeNavBar()} >

            <h3 className="text-uppercase"><i className="fa fa-wrench mr-2 " style={{ paddingTop: "25px" }} />{t('Support.asistencia')}</h3>

            <div className='d-flex justify-content-center '>
                <div className='col-12 col-md-8  rounded fondoTransOscuro pb-3'>
                    <Bounce>
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </Bounce>


                </div>
            </div>

            {/* {adsMode&&<MyAds/>} */}

            {sendOk ?
                <div className="alert alert-success mx-3 my-4" role="alert">
                    <div className="font-weight-bold">{t('Support.sendOk')}<i className="fa fa-check fa-2x ml-1" /> </div>
                    <div className="mt-2 small">{t('Support.sendOkText')}</div>
                </div>
                :
                <div className="container mt-4" style={{ paddingTop: "20px" }}>

                    <div className='d-flex justify-content-center'>
                        <div className='col-12 col-md-8'>

                            {/* <label htmlFor="country" className="mb-0 mt-2">TIPO DE CONSULTA:</label> */}
                            <h4 className="text-light">{t('Support.enviaConsulta')}</h4>

                            <Fade>

                                <div>
                                    {/* EMAIL */}
                                    <input type="email" className="form-control my-1"
                                        id="aEmail" name="aEmail"
                                        value={aEmail} onChange={(e) => setAEmail(e.target.value)}
                                        placeholder={t('Support.email')}
                                    />
                                </div>

                                <div>
                                    <select value={selectedQuery} className="form-control form-select" aria-label="Default select example" id="selectCountry" onChange={handleSelectChange} >
                                        <option value="">{t('Support.tipoConsulta')}</option>
                                        {queryType.map((query, index) => (
                                            <option key={query.value} value={query.label}>
                                                {query.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-4 mt-1">
                                    {/* <label htmlFor="aditionalInfo2" className="mb-0 mt-2">Deje su comentario</label> */}
                                    <textarea type="textarea" className="form-control"
                                        id="aditionalInfo2" name="aditionalInfo2"
                                        value={queryComment} onChange={(e) => setQueryComment(e.target.value)}
                                        placeholder="Escriba su consulta aquí"
                                        rows="7"
                                    />
                                </div>

                            </Fade>

                            {warnings.length > 0 && warnings.map((warning, index) => {
                                return (
                                    <div key={index} className="alert alert-danger small" role="alert"><i className="fa fa-warning" />  {warning}</div>
                                )
                            })}

                            <div className="my-4">
                                {!isLoading ?
                                    <button className="btn btn-block btn-outline-light" onClick={handleSendClick} disabled={false}>{t('Support.btnEnviar')}<i className="fa fa-paper-plane ml-1"/></button>
                                    :
                                    <button className="btn btn-block btn-outline-light" onClick={handleSendClick} disabled={true}><i className="fa fa-spinner fa-spin mr-1" />{t('Support.btnEnviando')}</button>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            }


        </div>
    )
}
export default Support