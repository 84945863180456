import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';

export function FeatureTitle(props) {

    const { ftName ,icon} = props
    return (
        <div className='text-left'>
             
            <div className="">
            <div className="fondoTransOscuro text-light text-uppercase py-2 px-2 small"><i className={`fa ${icon} mr-1 fa-2x`} />{ftName}</div>
            <hr className='bg-secondary py-0 my-0 mb-4'/>
            </div>
            
        </div>

    )
}

export function TermsPrompt(props) {
    const { t } = useTranslation();
  
    return (
  
      <div className="modal fade" id="termsPrompt" tabindex="-1" role="dialog" aria-labelledby="termsPrompt" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="termsPrompt">{t('Terms.terms1')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-dark text-left small">
              <h5 >{t('Terms.terms2')}</h5>
              {t('Terms.terms3')}
              <div className="font-weight-bold mt-3">{t('Terms.terms4')} </div>
              <div>{t('Terms.terms5')}</div>
              <div className="ml-2">
                <div ><b>{t('Terms.terms6')}</b> {t('Terms.terms7')}</div>
                <div><b>{t('Terms.terms8')}</b> {t('Terms.terms9')}</div>
                <div><b>{t('Terms.terms10')}</b> {t('Terms.terms11')}</div>
                <div><b>{t('Terms.terms12')}</b> {t('Terms.terms13')}</div>
                <div><b>{t('Terms.terms14')}</b> {t('Terms.terms15')}</div>
              </div>
              <div className="font-weight-bold mt-3"> {t('Terms.terms16')}</div>
              <div>{t('Terms.terms17')}</div>
              <div className="font-weight-bold mt-3">{t('Terms.terms18')} </div>
              <div>{t('Terms.terms19')}</div>
              <div className="font-weight-bold mt-3">{t('Terms.terms20')} </div>
              <div>{t('Terms.terms21')}</div>
              <div className="font-weight-bold mt-3"> {t('Terms.terms22')}</div>
              <div>{t('Terms.terms23')}</div>
              <div>{t('Terms.terms24')}</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => props.handleAcceptTerms(false)}>{t('Terms.terms25')}</button>
              <button type="button" className="btn btn-primary" onClick={() => props.handleAcceptTerms(true)}>{t('Terms.terms26')}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  


export function WaitPrompt(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();


    return (
        <div className="modal fade text-dark" id="waitPrompt" tabIndex="-1" role="dialog" aria-labelledby="waitPrompt" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-warning text-uppercase" id="exampleModalLabel"><i className="fa fa-spinner fa-spin mr-1" />{t("WaitPrompt.title")} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-left small">
                        <div className="h6 text-uppercase">{t("WaitPrompt.text1")}</div>
                        <div className="h6 mt-3">{t("WaitPrompt.text2")}</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>navigate("/")} >{t("WaitPrompt.close")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}