import React, { useEffect } from "react";
//AdSense
//import { Adsense } from '@ctrl/react-adsense';


export default function MyAds(props) {

 const { adStyle } = props

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    console.log(adStyle)

    if (adStyle == "inFeed")
        return (
            <ins className="adsbygoogle"
                style={{ display: 'block', marginTop: "10px" }}
                data-ad-format="fluid"
                data-ad-layout-key="-ef+6k-30-ac+ty"
                data-ad-client="ca-pub-9974395419392325"
                data-ad-slot="7805665726"></ins>)

    if (adStyle == "inArticle")
        return (
            <ins className="adsbygoogle"
                style={{ display: 'block', textAlign: 'center', marginTop: "10px" }}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-9974395419392325"
                data-ad-slot="5039175236"></ins>)

    if (adStyle == "multiplex")
        return (
            <ins className="adsbygoogle"
                style={{ display: 'block', marginTop: "10px" }}
                data-ad-format="autorelaxed"
                data-ad-client="ca-pub-9974395419392325"
                data-ad-slot="4193723779"></ins>
        )


    return (
        <ins className="adsbygoogle"
            style={{ display: 'block', marginTop: "22px" }}
            data-ad-client="ca-pub-9974395419392325"
            data-ad-slot="7206511910"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
    );
}
