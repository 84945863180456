export const COOKIE_USER= "cookie_user"
export const COOKIE_USER_CREATED= "cookie_user_created2"
export const COOKIE_QUOTA= "cookie_quota"
export const COOKIE_MOBILES= "cookie_mobiles"


//export const URL_BACKEND = "http://localhost:8080";
export const URL_BACKEND="https://mobile-locator.fly.dev"


export const MAX_MOBILES=2
//export const QUOTA_EXPIRES= (60)*1000 //  1 minute
export const QUOTA_EXPIRES= (6*60*60)*1000 //6 hours



