
export function resizeNavBar() {
    const mySidebar = document.getElementById("mySidebar")
    if (mySidebar) {
        if (window.innerWidth >= 1750) {
            openNavBar()
        } else {
            if (mySidebar) mySidebar.style.width = "0";
        }
    }
  }
  
  export function closeNavBar() {
    const mySidebar = document.getElementById("mySidebar")
    if (mySidebar) mySidebar.style.width = "0";
  }
  
  
  export function openNavBar() {
    const mySidebar = document.getElementById("mySidebar")
    if (mySidebar) mySidebar.style.width = "300px";
  }
  export function resize() {
    resizeNavBar()
  }
  