import { useEffect, useState, useRef, useContext, CSSProperties } from 'react';

import { InfinitySpin } from 'react-loader-spinner'
import { FeatureTitle } from './CommonVisuals'

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

//Maptiler
import Map from './Map.jsx';
// import Globe from 'react-globe.gl'

// //Mapa GPS con GoogleMaps
//import GoogleMaps from "simple-react-google-maps"

import { getRandomNumber, playAudio } from './functions';

import { useTranslation } from 'react-i18next';


export default function Location(props) {
    const { t } = useTranslation();

    const globeEl = useRef();
    const divDynamic = document.createElement('div');


    const { aLocation, currentNumber, isOwnNumber, currentChannel,demoMode } = props
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!aLocation) return null


        const init = async () => {
            setIsLoading(true)
            await new Promise(r => setTimeout(r, getRandomNumber(3000,6000)));
            setIsLoading(false)
            playAudio('location')
        }

        if (currentChannel == 'Location')init()
        



        // if (globeEl.current) {
        //     globeEl.current.pointOfView({ lat: geoLocation.location.latitude, lng: geoLocation.location.longitude, altitude: 1.5 }, 2000);

        //     divDynamic.innerHTML =
        //         `<div class="small" style='padding-top:150px'>
        // <button class='btn btn-danger btn-sm font' >${t('Location.verUbi')} <i class="fa fa-street-view" aria-hidden="true"></i></button>
        // <div class='small mt-1' style='text-transform: uppercase'> ${geoLocation.connection?.domain?.split('.')[0]}</div>
        // <div class='small'> ${geoLocation.connection.organization}</div>
        // <img src=${geoLocation.location.country.flag.wikimedia}  class='img-fluid' style='height:20px'  /> <span class='small' style=''>${geoLocation.location.country.name}</span>
        // </div>`;
        //     divDynamic.onclick = function () {
        //         alert('Button clicked!')
        //     }

        // }

        // setIsLoading(true)
        // setTimeout(() => { setIsLoading(false) }, getRandomNumber(1000, 10000));

    }, [currentChannel])

    if (currentChannel != 'Location') return null

    if (isLoading) return (
        <div className='rounded' style={{ height: "559px", width: "278px", backgroundColor: "#000", paddingTop: "50%" }}>
            <Fade duration={3000}>
                <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    src="/images/lottie-location.json"
                    style={{ height: '200px', width: '200px', padding: '0px' }}
                ></Player>
                <div className='text-center small text-secondary' >{t('Location.cargandoUbi')} {currentNumber}</div>
            </Fade >
        </div>

    );


    return (
        <div className='rounded' style={{ height: "559px", width: "278px", backgroundColor: "#000" }}>
            <Zoom>
                <div >

                    <FeatureTitle ftName={t('Location.lastLocation')} icon='fa-map-marker' />

                    <div className='d-flex justify-content-center mb-0'>
                        <img id="buyClick" src={aLocation.flag} alt="flag" className='img-fluid' style={{ height: "50px", marginTop: "20px" }} />
                        <div className='ml-3'>
                            <div><span className='text-danger font-weight-bold'>  {aLocation.country} - {aLocation.city} </span></div>
                            <div className='text-left small'>CP: <span className='text-danger font-weight-bold'> {aLocation.cp} </span> <span className='text-light font-weight-bold ml-2 small'> {aLocation.code} </span></div>
                            <div className='text-left small'>AREA:<span className='text-danger'>  {aLocation.code}</span></div>
                            <div className='text-left small'>LAT:<span className='text-danger'>  {aLocation.lat.toString().substring(0, 6) + "***"}</span> LONG:<span className='text-danger'>  {aLocation.lng.toString().substring(0, 6) + "***"}</span> </div>
                        </div>
                    </div>
                </div>


                <div className="p-0 mt-4 d-flex justify-content-center " >

                    <div style={{ position: "absolute", zIndex: "999", marginTop: "35%" }} >
                        <Player
                            autoplay={true}
                            loop={true}
                            controls={false}
                            src="/images/lottie-marker.json"
                            style={{ height: '120px', width: '120px', padding: '0px' }}
                        ></Player>
                        
                    </div>


                    {demoMode&&<button style={{ position: "absolute", zIndex: "999", marginTop: "20%" }} class='btn btn-dark btn-sm font' onClick={()=>props.handleBuy()}>{t('Location.verUbi')} <i class="fa fa-street-view" aria-hidden="true"></i></button>}

                    <Map lat={aLocation.lat} lng={aLocation.lng} oneUpdate={true} demoMode={demoMode} />
   
                    
                    {/* <Globe
                    ref={globeEl}
                    globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
                    backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
                    width="300px"
                    height="300px"
                    showGraticules={true}
                    //Puntos
                    pointsData={[{
                        lat: geoLocation.location.latitude,
                        lng: geoLocation.location.longitude,
                        size: 0.5,
                        color: 'red',
                        label: 'MÓVIL ENCONTRADO!',
                    }]}
                    pointAltitude="size"
                    pointColor="color"
                    pointLabel="label"
                    pointRadius={0.5}
                    pointsTransitionDuration={3000}
                    //Arcos
                    //arcsData={[{ startLat: 37.7749, startLng: -122.4194, endLat: 34.0522, endLng: -118.2437 }, { startLat: 40.7128, startLng: -74.0060, endLat: 51.5074, endLng: -0.1278 }, { startLat: 51.5074, startLng: -0.1278, endLat: 55.7558, endLng: 37.6176 }]}
                    Anillos
                    ringsData={[{
                        lat: geoLocation.location.latitude,
                        lng: geoLocation.location.longitude,
                        size: 0.8,
                        color: 'red',
                    }]}
                    ringColor="color"
                    //DIV PERSONALIZADO
                    htmlElementsData={[{ lat: geoLocation.location.latitude, lng: geoLocation.location.longitude }]}
                    htmlElement={divDynamic}
                    animateIn={true}
                    toRotate={true}
                    autoRotate={true}
                /> */}

                </div>

                {/* <GoogleMaps
                apiKey={"AIzaSyC_6gYSYaFPnbTcJ21R8hhspKYahwg2vR4"}
                style={{ height: "401px", width: "100%" }}
                zoom={10}
                center={{
                    lat: geoLocation.location.latitude,
                    lng: geoLocation.location.longitude
                }}
                markers={[{ lat: geoLocation.location.latitude, lng: geoLocation.location.longitude }]}
            /> */}
            </Zoom>
        </div>

    )




}