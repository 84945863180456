import { useTranslation } from 'react-i18next';
import { TermsPrompt } from './Features/CommonVisuals';
import React from 'react';

export default function Footer(props) {

  const { t } = useTranslation();



  const handleClick = () => {

    window.$('#termsPrompt').modal();
  };

  const handleAcceptTerms = () => {

    window.$('#termsPrompt').modal('hide');

  }


  return (
    <React.Fragment>
      <TermsPrompt handleAcceptTerms={handleAcceptTerms} />


      <div className={`footer text-secondary `}   >
        <div className="d-flex justify-content-center small mt-1 ">
          <small>
          {t('Footer.responsable')} <a onClick={handleClick} className='text'><u>{t('Footer.leerMas')}</u></a>
          </small>
        </div>
        <div className="mb-0 py-0 small"><small>{new Date().getFullYear()} Copyright © Catwatchful ™. All Rights Reserved. </small></div>
        {/* <div className="mb-0 py-0 small">{t('Footer.message')}</div> */}
      </div>
    </React.Fragment>
  )


}