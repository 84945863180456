import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { resizeNavBar } from './NavBarJs';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import MiContexto from '../MiContexto'
import MyAds from "../MyAds";



export default function HowToUse(props) {

    const { t } = useTranslation();
    const navigate = useNavigate()

    const { adsMode } = useContext(MiContexto)


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        resizeNavBar()
    }, [])


    const handleUnlok = () => {
        let current = Cookies.get('unlocked')
        if (current === 'true') {
            Cookies.set('unlocked', 'false', { expires: 1 })
        } else {
            Cookies.set('unlocked', 'true', { expires: 1 })
        }
    }


    return (

        <div className='container small' >



            <div className='h4 text-uppercase' style={{ paddingTop: "25px" }}>{t('HowToUse.title')}?</div>

            {/* {adsMode&&<MyAds adStyle={"auto"}/>} */}

            <div className='d-flex justify-content-center  '>

                <div className='col-12 col-md-8 fondoTransOscuro rounded p-4'>

                    <Fade>
                        <div className='mt-2 text-left'>
                            <div className=''>
                                <span className='circulo mr-2 blueShadow'>1</span>
                                <span className='font-weight-bold text-info text-uppercase'>{t('HowToUse.step1')}</span>
                            </div>
                            <div className='text-secondary'>
                                {t('HowToUse.step1Text')}
                            </div>
                        </div>
                    </Fade>
                    {/* {adsMode&&<MyAds adStyle={"auto"}/>} */}

                    <Fade>
                        <div className='text-left' style={{ marginTop: "50px" }}>
                            <div className=''>
                                <span className='circulo mr-2 blueShadow'>2</span>
                                <span className='font-weight-bold text-info text-uppercase'>{t('HowToUse.step2')}</span>
                            </div>
                            <div className='text-secondary'>
                                {t('HowToUse.step2Text')}
                            </div>
                        </div>
                    </Fade>

                    {/* {adsMode&&<MyAds adStyle={"inArticle"}/>} */}
                    <Fade>
                        <div className='text-left' style={{ marginTop: "50px" }} >
                            <div className=''>
                                <span className='circulo mr-2 blueShadow'>3</span>
                                <span className='font-weight-bold text-info text-uppercase'>{t('HowToUse.step3')}</span>
                            </div>
                            <div className='text-secondary'>
                                {t('HowToUse.step3Text')}
                            </div>
                        </div>
                    </Fade>


                    <Fade>
                        <div className='text-left' style={{ marginTop: "50px" }}>
                            <div className=''>
                                <span className='circulo mr-2 blueShadow' onClick={handleUnlok}>4</span>
                                <span className='font-weight-bold text-info text-uppercase'>{t('HowToUse.step4')}</span>
                            </div>
                            <div className='text-secondary'>
                                {t('HowToUse.step4Text')}
                            </div>
                        </div>
                    </Fade>


                    <Fade>
                        <div className='text-left' style={{ marginTop: "50px" }}>
                            <div className=''>
                                <span className='circulo mr-2 blueShadow'>5</span>
                                <span className='font-weight-bold text-info text-uppercase'>{t('HowToUse.step5')}</span>
                            </div>
                            <div className='text-secondary'>
                                {t('HowToUse.step5Text')}
                            </div>
                        </div>
                    </Fade>


                    {/* {adsMode&&<MyAds/>} */}

                    <div className='text-warning text-left' style={{ marginTop: "50px" }}>
                        <i className='fa fa-warning mr-1' />
                        <small>{t('HowToUse.explain')}</small>
                    </div>

                </div>
            </div>
        </div>
    )


}